import { localThemes } from "./theme";

import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/home";
import Signin from "./pages/signin";
import Register from "./pages/register";
import FaceLogin from "./pages/login";
import ForgetMePage from "./pages/forgetMe";
import FaceLoginWithDocument from "./pages/login_with_barcode";
import Authenticate from "./pages/authenticate";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import ContinuousAuthentication from "./pages/continuous_authentication";


const skin = localThemes?.includes(window?.location?.search?.split("skin=")[1])
  ? window?.location?.search?.split("skin=")[1]
  : "primary";
const themeName = skin || "primary";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home theme={themeName} skin={skin} />,
  },
  {
    path: "/signup",
    element: <Register theme={themeName} skin={skin} />,
  },
  {
    path: "/signin",
    element: <Signin theme={themeName} skin={skin} withBarcode={false} />,
  },
  {
    path: "/register",
    element: <Register theme={themeName} skin={skin} />,
  },
  {
    path: "/login",
    element: <FaceLogin skin={skin} theme={themeName} />,
  },
  {
    path: "/login_with_barcode",
    // element: <FaceLoginWithDocument skin={skin} theme={themeName} />
    element: <Signin theme={themeName} skin={skin} withBarcode={true} />
  },
  {
    path: "/forget_me",
    element: <ForgetMePage skin={skin} theme={themeName} />
  },
  {
    path: "/authenticate",
    element: <Authenticate theme={themeName} skin={skin} />,
  },
  {
    path: "/terms",
    element: <Terms theme={themeName} skin={skin} />,
  },
  {
    path: "/privacy",
    element: <Privacy theme={themeName} skin={skin} />,
  },
  {
    path: "/continuous_authentication",
    element: <ContinuousAuthentication theme={themeName} skin={skin} />,
  }
]);

export default router;
