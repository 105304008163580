import { useEffect, useContext } from "react";
import { Grid, Typography } from "@mui/material";

import { useStyles, styles } from "../../../styles/styles";
import CvsButton from "../../Button";
import { UserContext } from "../../../context/UserContext";
import { getStatusFromUser, SUCCESS } from "../../../utils";
import greenCheck from "../../../assets/greenCheck.png";
import { useNavigate } from "react-router";

const ForgetMeSuccess = ({
  skin,
  matchesSM,
}: {
  skin: string;
  matchesSM: boolean;
}) => {
  const classes = useStyles();
  const context = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      const status = getStatusFromUser(window.localStorage.getItem("user"));
      const { successUrl } = context.verificationSession;
      if (successUrl && status === SUCCESS) {
        window.location.href = successUrl;
      }
    }, 2000);
  }, []);
  return (
    <>
      <Grid style={styles.cardGrid} className={classes.cardGridMobile}>
        <Typography
          component="p"
          textAlign={"center"}
          fontSize={20}
          fontWeight={500}
          lineHeight={1.5}
          mt={2}
          className={classes.cardInnerHeading}
        >
          Account has been deleted.
        </Typography>
        <img
          src={greenCheck}
          alt=""
          className={classes.checkIcon}
          width={200}
        />
      </Grid>
      <Grid style={{ marginBottom: 30, height: 45 }}>
        <CvsButton
          title={"Go back to homepage"}
          onClick={() => {
            navigate("/");
          }}
          skin={skin}
        />
      </Grid>
    </>
  );
};

export default ForgetMeSuccess;
