import {
  Box,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useGlobalAudioPlayer } from "react-use-audio-player";
import { REMOVE_GLASSES } from "../../constants";
import { useSkinContext } from "../../context/SkinContext";
import STEPS from "../../pages/register/steps";
import { isMobile, stopCamera } from "../../utils";
import useToast from "../../utils/useToast";
import CvsButton from "../Button";
import { useStyles } from "./styles";
import sound from "../../assets/sound/success.mp3";

export const SWITCH_DEVICE = "Switch Device";
export const userHelpUI = {
  none: "none",
  message: "message",
  messageIcon: "messageIcon",
  fullError: "fullError",
};
let timeCount = 25000;
const CanvasLayout = ({
  setStep,
  loader,
  ready,
  hideMessage,
  enrollOneFaProgress,
  message,
}: any) => {
  const { skin } = useSkinContext();
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const timeoutRefs = useRef<any[]>([]);
  const canvasRef = useRef<null | HTMLCanvasElement>(null);
  const timeoutCount = useRef(0);
  const classes = useStyles();
  const [isAgeAvailable, setIsAgeAvailable] = useState(false);
  const [userHelp, setUserHelp] = useState(userHelpUI.none);
  const [isGlasses, setIsGlasses] = useState(false);
  const { showToast } = useToast();
  const { load } = useGlobalAudioPlayer();
  const onSoundPlay = async () => {
    load(sound, {
      autoplay: true
    });
  };

  useEffect(() => {
    console.log({message})
    if (message === REMOVE_GLASSES) {
      setIsGlasses(true);
    }
  }, [message]);

  useEffect(() => {
    if (enrollOneFaProgress > 0) {
      setTimeouts();
      requestAnimationFrame(draw);
      if (enrollOneFaProgress === 100 && isGlasses) {
        onSoundPlay();
      }
    }
  }, [enrollOneFaProgress]);

  function draw() {
    const canvas = canvasRef.current;
    if (canvas) {
      let ctx = canvas.getContext("2d");
      if (!ctx) return;
      const start = 4.72;
      const boundingRect = canvas.getBoundingClientRect();
      const displayedWidth = boundingRect.width;
      const displayedHeight = boundingRect.height;
      let radius = Math.min(displayedWidth, displayedHeight) / 2.5;
      let centerX = displayedWidth / 2;
      let centerY = displayedHeight / 2;
      if (isGlasses) {
        ctx.fillStyle = "#008000a3" as string; // Set the fill color
        ctx.beginPath();
        ctx.arc(
          centerX,
          centerY,
          radius,
          start,
          (enrollOneFaProgress / 100) * Math.PI * 2 + start
        );
        ctx.lineTo(centerX, centerY); // Connect the endpoint of the arc to the center
        ctx.closePath();
        ctx.fill(); // Fill the circle
      } else {
        ctx.strokeStyle = "green" as string;
        ctx.lineWidth = 5;
        ctx.lineCap = "round";
        ctx.beginPath();
        ctx.arc(
          centerX,
          centerY,
          radius,
          start,
          (enrollOneFaProgress / 100) * Math.PI * 2 + start
        );
        ctx.stroke();
      }
    }
  }

  useEffect(() => {
    let ctx: CanvasRenderingContext2D | null = null;
    const canvas = canvasRef.current;

    if (canvas && ready) {
      ctx = canvas.getContext("2d");
      if (ctx) {
        const boundingRect = canvas.getBoundingClientRect();
        const displayedWidth = boundingRect.width;
        const displayedHeight = boundingRect.height;
        let radius = Math.min(displayedWidth, displayedHeight) / 2.5;
        let centerX = displayedWidth / 2;
        let centerY = displayedHeight / 2;
        ctx.clearRect(0, 0, displayedWidth, displayedHeight);
        canvas.width = displayedWidth;
        canvas.height = displayedHeight;

        ctx.globalAlpha = 0.4;
        ctx.fillStyle = "#e0e0e0";
        ctx.fillRect(0, 0, displayedWidth, displayedHeight);
        ctx.globalAlpha = 1;

        ctx.globalCompositeOperation = "destination-out";
        ctx.beginPath();
        ctx.ellipse(centerX, centerY, radius, radius, 0, 0, 2 * Math.PI);
        ctx.fill();
        ctx.globalCompositeOperation = "source-over";
      }
    }
  }, [ready, isAgeAvailable, enrollOneFaProgress, isGlasses]);

  useEffect(() => {
    if (loader) setIsAgeAvailable(true);
  }, [loader]);

  useEffect(() => {
    if (!ready) return;

    setTimeouts();
    return () => clearSetTimeouts();
  }, [ready]);

  const clearSetTimeouts = () =>
    timeoutRefs.current.forEach((timeout) => {
      clearTimeout(timeout);
    });

  function setTimeouts() {
    clearSetTimeouts();
    timeoutRefs.current = [];
    timeoutCount.current = timeoutCount.current + 1;
    timeoutRefs.current.push(
      setTimeout(() => {
        setUserHelp(userHelpUI.message);
      }, 5000)
    );
    timeoutRefs.current.push(
      setTimeout(() => {
        if (timeoutCount.current === 4) {
          if (isMobile) {
            showToast("Please try again later.", "error");
          } else {
            setStep(STEPS?.SWITCH_DEVICE);
          }
        }
        hideMessage(true);
        setUserHelp(userHelpUI.fullError);
      }, timeCount)
    );
  }

  function renderUserHelp(help: string) {
    if (isAgeAvailable) return null;
    switch (help) {
      case userHelpUI.none:
        return null;

      case userHelpUI.message:
        return (
          <Box
            display={"flex"}
            justifyContent={"center"}
            width={"100%"}
            height={"25px"}
            alignItems={"center"}
            sx={{
              backgroundColor: "rgba(0,0,0,0.4)",
            }}
            className={classes.boxStyle}
          >
            <Typography variant="body2" fontWeight={600}>
              {message}
            </Typography>
          </Box>
        );

      case userHelpUI.fullError:
        return (
          <Grid
            container
            direction={"column"}
            justifyContent={"space-between"}
            flexWrap={"nowrap"}
            position={"absolute"}
            left={0}
            top={1}
            height={matchesSM ? "99.5%" : "100%"}
            width={"100%"}
            sx={{
              backgroundColor: "rgba(0,0,0,0.7)",
            }}
            borderRadius={"10px"}
          >
            <Grid item>
              <Typography variant="h4" textAlign={"center"} mt={1}>
                Having Difficulties?
              </Typography>
            </Grid>
            <Grid item direction={"column"} alignItems={"center"} container>
              <Typography variant="body2" textAlign={"center"} mt={1}>
                Three tips for a great selfie:
              </Typography>
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 7,
                }}
              >
                <ListItem
                  sx={{
                    display: "list-item",
                  }}
                >
                  <Typography variant="body2">Turn on the lights</Typography>
                </ListItem>
                <ListItem
                  sx={{
                    display: "list-item",
                  }}
                >
                  <Typography variant="body2">
                    Remove hat, glasses and facemask
                  </Typography>
                </ListItem>
                <ListItem
                  sx={{
                    display: "list-item",
                  }}
                >
                  <Typography variant="body2">
                    Place your head in the oval
                  </Typography>
                </ListItem>
              </List>
            </Grid>
            <Grid item container direction="column" spacing={1} p={1}>
              <Grid item>
                <CvsButton
                  title="Try again"
                  onClick={() => {
                    setUserHelp(userHelpUI.none);
                    setTimeouts();
                  }}
                  skin={skin}
                />
              </Grid>
              {!matchesSM && (
                <Grid item style={{ marginTop: -18 }}>
                  <CvsButton
                    title="Switch to Mobile Device"
                    onClick={() => {
                      setStep(STEPS.SWITCH_DEVICE);
                      stopCamera();
                    }}
                    skin={skin}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        );
    }
  }

  return (
    <>
      <Grid
        display={"flex"}
        justifyContent={"center"}
        sx={{
          position: "absolute",
          top: 1,
          left: 2,
          width: "100%",
          height: "98%",
          zIndex: 1,
        }}
      >
        {renderUserHelp(userHelp)}
      </Grid>
      <canvas
        ref={canvasRef}
        className="camera-canvas"
        style={{
          position: "absolute",
          top: 2,
          left: 2,
          width: "100%",
          height: matchesSM ? 350 : 490,
        }}
      />
    </>
  );
};

export default CanvasLayout;
