import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
    "& .MuiDialog-paper": {
      margin: "0px",
      backgroundColor: "transparent",
      justifyContent: "center",
      [theme.breakpoints.between("xs", "sm")]: {
        backgroundColor: "#fff",
        justifyContent: "flex-start",
      },
    },
    "& .MuiDialog-scrollPaper": {
      [theme.breakpoints.between("xs", "sm")]: {
        flex: 1,
      },
    },
  },
  homeModalCover: {
    width: "100%",
    // height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    border: "none",
    outline: "none",
    [theme.breakpoints.between("xs", "sm")]: { },
    [theme.breakpoints.down(380)]: {
      height: "90vh",
    },
  },
  modalBoxLogo: {
    padding: "5px 8px",
    borderRadius: 5,
  },
  modalBoxLogoWrap: {
    backgroundColor: "#333",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  cardInner: {
    maxWidth: 700,
    backgroundColor: "transparent !important",
    border: "none !important",
    boxShadow: "unset !important",
    borderRadius: "10px !important",
    overflow: "visible !important",
    padding: 0,
    width: "100%",
    margin: "0px !important",
    "@media (min-width: 1200px) and (max-width: 1300px)": {
      marginTop: "25px !important",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
      maxWidth: "450px",
      marginTop: "0px",
      height: " calc(100% - 115px)",
      boxShadow: "unset !important",
      border: "none !important",
      margin: "0 auto !important",
    },
    [theme.breakpoints.down(380)]: {
      height: "calc(100% - 40px)",
    },
    "& hr": {
      [theme.breakpoints.between("xs", "sm")]: {
        display: "none",
      },
    },
  },
  cardChild: {
    padding: 0,
    display: "flex",
    alignItems: "center",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    paddingTop: "3rem !important",
    [theme.breakpoints.between("xs", "sm")]: {
      overflow: "hidden",
      height: "100%",
      // paddingLeft: "16px !important",
      paddingRight: "2px !important",
      paddingTop: "0rem !important",
    },
    "@media (orientation: landscape)": {
      [theme.breakpoints.down(1024)]: {
        paddingBottom: "0px !important",
        paddingTop: "0rem !important",
      },
    },
  },
  footer: {
    bottom: "-10px",
    "@media (max-width: 1400px)": {
      bottom: "-43px",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      bottom: "0px",
      fontSize: 13,
      width: "100%",
      justifyContent: "center",
      marginLeft: 0,
      color: "#000",
    },
    "& li": {
      justifyContent: "center",
    },
  },
  linkbox: {
    marginLeft: "15px !important",
    textDecoration: "unset  !important",
    cursor: "pointer",
    fontSize: 16,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 13,
      textAlign: "center",
      color: "#000 !important",
    },
  },
  closeIcon: {
    position: "absolute",
    right: 8,
    top: 9,
    cursor: "pointer",
    color: "#fff",
  },
  setfieImage: {
    [theme.breakpoints.between("xs", "sm")]: {
      display: "block",
      margin: "0 auto",
    },
  },
  hintsWrap: {
    border: "1px solid #ccc",
    height: "295px",
    width: "210px",
    padding: "10px",
    borderRadius: "10px",
    marginTop: -37,
    [theme.breakpoints.down(1024)]: {
      display: "none",
    },
  },
}));
