import {
  Box,
  CircularProgress,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useStyles, styles } from "../AuthenticateComponents/Login/styles";
import React, { useEffect, useState } from "react";
import homeStyles from "../../styles/Home.module.css";
import Camera from "../Camera";
import { UserContext } from "../../context/UserContext";
import {
  closeCamera,
  updateUser,
  uploadPortrait,
  convertCroppedImage,
} from "@privateid/cryptonets-web-sdk-alpha";
import shield from "../../assets/shield.png";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import STEPS from "../../pages/register/steps";
import { onStepChange, stopCamera } from "../../utils";
import SpinnerLoader from "../SpinnerLoader";
import { ELEMENT_ID } from "../../constants";

import useEnrollOneFaWithLiveness from "../../hooks/useEnrollOneFaWithLiveness";
import { ClassNameMap } from "@mui/styles";
import { detect, BrowserInfo } from "detect-browser";

function EnrollProgressMessage(props: {
  enrollOneFaProgress: number;
  classes: ClassNameMap;
}) {
  if (props.enrollOneFaProgress === 100) {
    return (
      <Typography
        align="center"
        fontSize={14}
        fontWeight={700}
        mt={1}
        mb={1}
        display={"flex"}
        alignItems="center"
        justifyContent={"center"}
      >
        <CircularProgress className={props.classes.progressBar} /> Verifying
        User, Please wait...
      </Typography>
    );
  }
  return (
    <Typography align="center" fontSize={14} fontWeight={700} mt={1} mb={1}>
      Please look at the camera to enroll
    </Typography>
  );
}

const Enroll = ({
  setStep,
}: {
  onReadyCallback?: (e: boolean) => void;
  onSwitchCamera?: () => void;
  message?: string;
  setStep: React.Dispatch<React.SetStateAction<string>>;
  skin: string;
}) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const context = React.useContext(UserContext);
  const { id, setGUID, setUUID, setDlAction } = context;
  const [showSuccess, setShowSuccess] = useState(false);
  const [loader, setLoader] = useState(false);
  // @ts-ignore
  const browserInfo: BrowserInfo = detect();
  const { os } = browserInfo;
  const {
    enrollUserOneFa,
    progress: enrollOneFaProgress,
    enrollStatus,
    enrollPortrait,
    enrollData,
  } = useEnrollOneFaWithLiveness("userVideo", () => { }, 4);

  useEffect(() => {
    if (enrollData?.uuid && enrollData?.guid && enrollPortrait) {
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
      }, 1500);
      handleUserUpdate(enrollData?.guid, enrollData?.uuid, enrollPortrait);
    }
  }, [enrollData?.uuid, enrollPortrait]);
  const handleUserUpdate = async (guid: string, uuid: string, enrollInageData: ImageData) => {
    setGUID(guid);
    setUUID(uuid);
    if (enrollInageData) {
      context.setEnrollImageData(enrollInageData);
      const enrollPortraitBase64 = await convertCroppedImage(
        enrollInageData.data,
        enrollInageData.width,
        enrollInageData.height
      );
      await uploadPortrait({
        id,
        portrait: enrollPortraitBase64,
      });
      const params = {
        id,
        attributes: {
          guid,
          uuid,
        },
      };
      const updateRes = (await updateUser(params)) as any;
      if (updateRes.guid && updateRes.uuid) {
        localStorage.setItem("uuid", JSON.stringify(uuid || {}));
        setShowSuccess(true);
        setTimeout(async () => {
          await closeCamera(ELEMENT_ID);
          setDlAction("frontscan");
          onStepChange(STEPS.ENROLL, setStep, STEPS.DRIVERLICENSE);
        }, 2000);
      }
    }
  };

  const onCameraFail = async () => {
    setStep(STEPS.SWITCH_DEVICE);
  };

  const cameraPermissionCheckAndEnroll = async (e: boolean) => {
    if (e) {
      await enrollUserOneFa();
    } else {
      setStep(STEPS.SWITCH_DEVICE);
    }
  };

  const onWasmLoadFail = () => {
    setStep(STEPS.SWITCH_DEVICE);
  };

  return (
    <>
      <Box
        position={"relative"}
        padding={"0px"}
        mt={0}
        pr={"2px"}
      >
        {(showSuccess || enrollOneFaProgress === 100) && (
          <Box
            style={
              {
                ...styles.overlayCamera,
                top: matchesSM ? 45 : "-5px",
                height: matchesSM ? "88%" : "100%",
              } as React.CSSProperties
            }
          >
            {showSuccess ? (
              <img
                src={shield}
                alt="shield"
                style={styles.shield as React.CSSProperties}
              />
            ) : (
              <SpinnerLoader />
            )}
          </Box>
        )}
        <>
          <div id="canvasInput" className={homeStyles.container}>
            <Camera
              onReadyCallback={cameraPermissionCheckAndEnroll}
              onSwitchCamera={() => { }}
              onCameraFail={onCameraFail}
              message={enrollStatus}
              onWasmLoadFail={onWasmLoadFail}
              setStep={setStep}
              loader={loader}
              canvasLayout={true}
              enrollOneFaProgress={enrollOneFaProgress}
              hideInstructionOverlay={false}
            />
          </div>
          {/* {os !== "iOS" && os !== "Android OS" &&
            <Box className={classes.otherOptions}>
              <Typography
                component="p"
                textAlign={matchesSM ? "center" : "left"}
                fontSize={15}
                fontWeight={500}
                mt={2}
                onClick={() => {
                  setStep(STEPS.SWITCH_DEVICE);
                  stopCamera();
                }}
              >
                <PhoneIphoneIcon /> Switch to Mobile Device
              </Typography>
            </Box>
          }
          <Box style={{ height: 50 }}>
            <EnrollProgressMessage
              enrollOneFaProgress={enrollOneFaProgress}
              classes={classes}
            />
          </Box> */}
        </>
      </Box>
    </>
  );
};
export default Enroll;
