import { useEffect, useState } from "react";
import styles from "../../styles/Home.module.css";
import useScanFrontDocumentWithoutPredict from "../../hooks/useScanFrontDocumentWithoutPredict";
import Camera from "../Camera";
import { getScanFrontColor, getScanFrontMessage } from "../../constants";
import Card from "../../assets/card.svg";
import { getFrontDocumentStatusMessage } from "@privateid/cryptonets-web-sdk-alpha/dist/utils";

const FaceCompareFrontDocument = ({
  onSuccess,
  onReadyCallback,
  onFailCallback,
  onCameraFail,
  enrollImageData,
  setOpStatus,
  setStep,
}: {
  onSuccess: (e: any) => void;
  onReadyCallback: (e: boolean) => void;
  onFailCallback: (e: { status: string; message: string }) => void;
  onCameraFail: (e: any) => void;
  enrollImageData: any;
  setOpStatus: (e: number) => void;
  setStep: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [isReady, setIsReady] = useState(false);
  const handleFrontSuccess = (result?: any) => {
    onSuccess?.(result);
  };
  const { scanFrontDocument, resultResponse } =
    useScanFrontDocumentWithoutPredict(
      handleFrontSuccess,
      onFailCallback,
      enrollImageData
    ) as any;
  useEffect(() => {
    setOpStatus(resultResponse?.op_status);
  }, [resultResponse]);

  const handleScanDLFront = async (e: boolean) => {
    setIsReady(e);
    onReadyCallback?.(e);
    if (e) {
      await scanFrontDocument();
    }
  };

  const returnMessage = () => {
    return getFrontDocumentStatusMessage(resultResponse?.op_status);
  };
  return (
    <div id="canvasInput" className={`${styles.container} documentCamera`}>
      {isReady && (
        <>
          <div
            className={styles.cameraFrame}
            style={{
              borderColor: getScanFrontColor(resultResponse?.op_status),
            }}
          >
            <img src={Card} alt="" />
          </div>
          <div className={styles.cameraFrameOuter} />
        </>
      )}
      <Camera
        onReadyCallback={handleScanDLFront}
        onSwitchCamera={handleScanDLFront}
        onCameraFail={onCameraFail}
        style={{ height: "unset" }}
        mode={"back"}
        requireHD={true}
        message={returnMessage()}
        isDocumentScan={true}
        setStep={setStep}
        hideInstructionOverlay={false}
      ></Camera>
    </div>
  );
};

export default FaceCompareFrontDocument;
