import { useState } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styles } from "../../styles/styles";
import { useNavigate } from "react-router";
import { createVerificationSession } from "../../services/api";
import { createSearchParams } from "react-router-dom";
import config from "../../config";
import { useStyles } from "../../pages/home/styles";
import { navigateToUrl } from "../../utils";
import TooltipContainer from "../TooltipContainer";
import bannerImage from "../../assets/banner.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface HomeProps {
  theme?: string;
  skin?: string;
}
const HomeComponent = ({ theme, skin }: HomeProps) => {
  const user = JSON.parse(localStorage.getItem("user") ?? "{}");
  const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
  const [loading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState<any>("");
  const [flow] = useState(1);
  const navigate = useNavigate();
  const classes = useStyles();
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));

  const createVerification = async (type?: string) => {
    if (user?._id || uuid?.length > 0) {
      localStorage.removeItem("user");
      localStorage.removeItem("uuid");
    }
    if ((user?._id || user?.token) && user?.successUrl) {
      navigateToUrl(user?.successUrl, user?.token);
      return;
    }
    setLoadingType(type);
    setLoading(true);
    const payload = {
      ...config.clientConfig,
      productGroupId: "intergalactic",
    };
    const result: any = await createVerificationSession(payload);
    if (result?.token) {
      onFlowChange(flow, result?.token, type);
    }
    setLoadingType("");
    setLoading(false);
  };

  const onFlowChange = (flow: number, token: string, type?: string) => {
    switch (flow) {
      case 1:
        // change this from navigate to using window.location.href
        window.location.href = `/register?token=${token}&skin=${skin}${
          type === "ssn" ? `&bypassSsn=true` : ""
        }`;
        break;
      // });
      case 2:
        return navigate({
          pathname: "/register",
          search: createSearchParams({
            token: token || "",
            age: "18",
          }).toString(),
        });
      case 3:
        return navigate({
          pathname: "/register",
          search: createSearchParams({
            token: token || "",
            age: "16",
          }).toString(),
        });
      case 4:
        return navigate({
          pathname: "/register",
          search: createSearchParams({
            token: token || "",
            ageCreditCheck: "true",
          }).toString(),
        });

      case 5:
        return navigate({
          pathname: "/register",
          search: createSearchParams({
            token: token || "",
            step: "9",
          }).toString(),
        });

      default:
        break;
    }
  };

  const onAllThreeAuthenticate = () => {
    if (user?._id || uuid?.length > 0) {
      localStorage.removeItem("user");
      localStorage.removeItem("uuid");
    }
    navigate("/login_with_barcode");
  };

  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={matchesSM ? 12 : 6}>
            <Box className={classes.mainWrap}>
              <Box className={classes.innerWrap}>
                <Typography
                  component="p"
                  color={`#262626`}
                  fontSize={matchesSM ? 31 : 36}
                  fontWeight={400}
                  className={classes.homeSubHeading}
                >
                  CVS Verified Identity
                </Typography>
              </Box>
              <Box pt={1} className={classes.buttonsGrid}>
                <Grid
                  container
                  alignItems="flex-start"
                  flexDirection="column"
                  className={classes.buttonsWrap}
                >
                  <TooltipContainer content="Consumer IAL 2 Registration (does not include SSN4 for the demo)">
                    <Button
                      sx={{
                        textTransform: "unset",
                        opacity: loading ? 0.8 : 1,
                      }}
                      variant="contained"
                      style={styles.ageVerifiedButton}
                      onClick={() => createVerification("ssn")}
                      disabled={loading}
                      className={classes.buttonsWrapButton}
                      endIcon={
                        loading && loadingType === "ssn" ? (
                          ""
                        ) : (
                          <ArrowForwardIosIcon />
                        )
                      }
                    >
                      {loading && loadingType === "ssn" ? (
                        <CircularProgress className={classes.homeLoader} />
                      ) : (
                        "Consumer Registration"
                      )}
                    </Button>
                  </TooltipContainer>
                  <TooltipContainer content="Workforce IAL 2 Registration (Includes SSN4)">
                    <Button
                      sx={{
                        textTransform: "unset",
                        opacity: loading ? 0.8 : 1,
                      }}
                      variant="contained"
                      style={styles.ageVerifiedButton}
                      onClick={() => createVerification()}
                      disabled={loading}
                      className={classes.buttonsWrapButton}
                      endIcon={
                        loading && !loadingType ? "" : <ArrowForwardIosIcon />
                      }
                    >
                      {loading && !loadingType ? (
                        <CircularProgress className={classes.homeLoader} />
                      ) : (
                        "Workforce Registration"
                      )}
                    </Button>
                  </TooltipContainer>
                  <TooltipContainer content="Authenticate with 3 factors (Face, liveness, and barcode from Photo ID)">
                    <Button
                      sx={{
                        textTransform: "unset",
                        opacity: loading ? 0.8 : 1,
                      }}
                      variant="contained"
                      style={styles.ageVerifiedButton}
                      onClick={() => onAllThreeAuthenticate()}
                      disabled={loading}
                      className={classes.buttonsWrapButton}
                      endIcon={<ArrowForwardIosIcon />}
                    >
                      Authenticate with barcode
                    </Button>
                  </TooltipContainer>
                </Grid>
              </Box>
              {matchesSM ? (
                <Button
                  style={styles.ageLearnMoreButton}
                  sx={{ textTransform: "unset" }}
                  className={classes.buttonsWrapButton}
                >
                  Learn more
                </Button>
              ) : null}
            </Box>
          </Grid>
          {!matchesSM && (
            <Grid item xs={matchesSM ? 12 : 6}>
              <div className="homeSidebarImg">
                <img src={bannerImage} alt="women" />
              </div>
            </Grid>
          )}
        </Grid>
        <Grid className={classes.bottomGrid}>
          <Typography textAlign={matchesSM ? 'center': 'left'} fontWeight={"700"} fontSize={"28px"}>
            Need help?
          </Typography>
          <Typography textAlign={matchesSM ? 'center': 'left'} fontSize={16}>
            Click here to find out how CVS Verified Identity works.
          </Typography>
          <Typography textAlign={matchesSM ? 'center': 'left'} fontSize={16}>
            If you are unable to verify your identity, please{" "}
            <a
              href="https://www.cvs.com/minuteclinic/info/contact"
              style={{ color: "#262626" }}
            >
              contact us
            </a>
            .
          </Typography>
        </Grid>
      </Container>
    </>
  );
};

export default HomeComponent;
