import {
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styles, useStyles } from "../../../styles/styles";
import IdPhoneImage from "../../../assets/phone-scan-front.png";
import IdLaptopImage from "../../../assets/Hand-scan-front-laptop.png";
import STEPS from "../../../pages/register/steps";
import { HeadingWrap } from "../InsuranceCard";
import CvsButton from "../../Button";

interface PreDocumentProps {
  theme: string;
  skin: string;
  setStep: (e: string) => void;
}

const PreDocument = (props: PreDocumentProps) => {
  const { skin, setStep } = props;
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const classes = useStyles();
  return (
    <>
      <Grid style={styles.cardGrid} className={classes.cardGridMobile}>
        <HeadingWrap classes={classes}>
          Get ready to scan your
          <br /> US Driver's License or ID Card
        </HeadingWrap>
        <img
          src={matchesSM ? IdPhoneImage : IdLaptopImage}
          alt=""
          className={classes.dlImage}
        />
      </Grid>
      <CvsButton
        title="Continue"
        onClick={() => {
          setStep(STEPS.DRIVERLICENSE);
        }}
        skin={skin}
      />
      <CvsButton
        title="I do not have my Photo ID"
        onClick={() => {
          setStep(STEPS.PERSONAL_DETAILS);
        }}
        skin={skin}
      />
    </>
  );
};

export default PreDocument;
