const STEPS = {
    START: "START",
    LOGIN: "LOGIN",
    PRE_DOCUMENT_SCAN: "PRE_DOCUMENT_SCAN",
    BACK_DOCUMENT_SCAN: "BACK_DOCUMENT_SCAN",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    SWITCH_DEVICE: "SWITCH_DEVICE",
  };
  
  export default STEPS;
  