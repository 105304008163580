import { Box, Divider, Grid, Typography } from "@mui/material";

import { useStyles, styles } from "../../../styles/styles";
import { theme as Theme } from "../../../theme";
import heartLogo from "../../../assets/heart-logo-white.png";
import { useNavigate } from "react-router";
import { useContext, useEffect } from "react";
import { LoginContext } from "../../../context/LoginContext";
import { postLoginAttemp } from "../../../services/api";
import successThumb from "../../../assets/success-yellow.gif";
import CvsButton from "../../Button";

export const FailureMessage = ({
  classes,
  message,
  palette,
  skin,
  matchesSM,
}: any) => {
  const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
  return (
    <Grid
      style={{ ...styles.cardGrid, backgroundColor: "#FFE97D" }}
      className={classes.cardGridMobile}
    >
      <img
        src={successThumb}
        alt=""
        className={classes.checkIcon}
        width={matchesSM ? "100%" : 400}
        style={{ transform: "rotate(180deg)" }}
      />
      <Typography
        component="p"
        textAlign={"center"}
        fontSize={25}
        fontWeight={500}
        lineHeight={1.5}
        mt={2}
        className={classes.cardInnerHeading}
        px={matchesSM ? 5 : ""}
      >
        {message
          ? message
          : "Sorry, we were unable to verify you at this time."}
      </Typography>
      {uuid?.length && (
        <Typography
          component="p"
          textAlign={"center"}
          fontSize={15}
          fontWeight={500}
          lineHeight={1.5}
          mt={0}
          className={classes.cardInnerHeading}
        >
          UUID : {uuid}
        </Typography>
      )}
    </Grid>
  );
};

const LoginFailure = ({
  skin,
  matchesSM,
  showToast,
  message,
  startAgainUrl,
  postToOidc = () => {},
  userData,
}: {
  skin: string;
  matchesSM: boolean;
  postToOidc: () => void;
  showToast?: () => void;
  message?: string;
  startAgainUrl?: string;
  userData?: any;
}) => {
  const classes = useStyles();
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;

  const loginContext = useContext(LoginContext);

  const logUserFailureAttempt = async () => {
    const userData = {
      status: "failure",
      predictedGUID: loginContext.predictedGUID || "none",
      barcodeHash: loginContext.barcodeHash,
      error: true,
      errorMessage: loginContext.errorMessage,
    };
    const res = await postLoginAttemp(userData);
  };
  const navigate = useNavigate();
  useEffect(() => {
    logUserFailureAttempt();
    setTimeout(() => {
      postToOidc();
    }, 3000);
  }, []);
  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent={"center"}
        className={classes.cardHeaderWrap}
      >
        <Typography
          component="p"
          textAlign="center"
          fontSize={16}
          fontWeight={900}
          letterSpacing={"1px"}
          sx={{ paddingTop: 2, paddingBottom: 2 }}
          className={classes.cardHeading}
        >
          <img
            src={heartLogo}
            alt=""
            width={35}
            className={classes.headerBoxLogo}
          />
          Login Failed!
        </Typography>
      </Grid>
      {!matchesSM && <Divider color={palette?.[skin]?.listText} />}
      <FailureMessage
        classes={classes}
        message={message || loginContext.errorMessage}
        palette={palette}
        skin={skin}
      />
      <Grid style={{ marginBottom: 20 }}>
        <Box>
          <CvsButton
            title={"Try again"}
            onClick={() => {
              navigate(0);
            }}
            skin={skin}
          />
        </Box>
      </Grid>
    </>
  );
};

export default LoginFailure;
