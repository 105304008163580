import { useEffect, useContext } from "react";
import { Divider, Grid, Typography } from "@mui/material";

import { useStyles, styles } from "../../../styles/styles";
import { theme as Theme } from "../../../theme";
import { UserContext } from "../../../context/UserContext";
import { getStatusFromUser, SUCCESS } from "../../../utils";
import heartLogo from "../../../assets/heart-logo-white.png";
import successThumb from "../../../assets/success-yellow.gif";
import { postLoginAttemp } from "../../../services/api";
import { LoginContext } from "../../../context/LoginContext";
import { useNavigate } from "react-router";
import CvsButton from "../../Button";

const LoginSuccess = ({
  skin,
  matchesSM,
  postToOidc = () => {},
}: {
  skin: string;
  matchesSM: boolean;
  postToOidc: () => void;
}) => {
  const classes = useStyles();
  const mainTheme = Theme;
  const context = useContext(UserContext);
  const palette: { [key: string]: any } = mainTheme.palette;
  const navigate = useNavigate();
  const loginContext = useContext(LoginContext);

  useEffect(() => {
    setTimeout(() => {
      const status = getStatusFromUser(window.localStorage.getItem("user"));
      const { successUrl } = context.verificationSession;
      if (successUrl && status === SUCCESS) {
        window.location.href = successUrl;
      }
    }, 2000);
  }, []);

  const logUserSuccessAttempt = async () => {
    const userData = {
      status: "success",
      predictedGUID: loginContext.predictedGUID,
      barcodeHash: loginContext.barcodeHash,
      error: false,
    };
    const res = await postLoginAttemp(userData);
  };

  useEffect(() => {
    logUserSuccessAttempt();
    setTimeout(() => {
      postToOidc();
    }, 3000);
  }, []);

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent={"center"}
        className={classes.cardHeaderWrap}
      >
        <Typography
          component="p"
          textAlign="center"
          fontSize={16}
          fontWeight={900}
          letterSpacing={"1px"}
          sx={{ paddingTop: 2, paddingBottom: 2 }}
          className={classes.cardHeading}
        >
          <img
            src={heartLogo}
            alt=""
            width={35}
            className={classes.headerBoxLogo}
          />
          Login Successful!
        </Typography>
      </Grid>
      {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
      <Grid
        style={{ ...styles.cardGrid, backgroundColor: "#FFE97D" }}
        className={classes.cardGridMobile}
      >
        <img
          src={successThumb}
          alt=""
          className={classes.checkIcon}
          width={400}
        />
      </Grid>
      <Grid style={{ marginBottom: 30, height: 80 }}>
        <Typography
          component="p"
          textAlign={"center"}
          fontSize={20}
          fontWeight={500}
          lineHeight={1.5}
          mt={1}
          className={classes.cardInnerHeading}
          color={palette?.[skin]?.primaryColor}
        >
          Thank you for using CVS!
        </Typography>
        <CvsButton
          title={"Continue"}
          onClick={() => {
            navigate("/");
          }}
          skin={skin}
        />
      </Grid>
    </>
  );
};

export default LoginSuccess;
