
const SpinnerLoader = () => {
  return (
    <>
      <span className="loader"></span>
    </>
  );
};

export default SpinnerLoader;
