import * as yup from "yup";

export const registerInputsSchema = yup.object().shape({
  phone: yup.string().matches(/^\d{10}$/, "Invalid Phone Number."),
  email: yup.string().email("Please enter a valid Email."),
});

export const ssnInputsSchema = yup.object().shape({
  ssn: yup.number().min(4, "SSN4 Must be 4 digits."),
});
