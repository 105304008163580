import { useContext, useEffect, useState } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { ClassNameMap } from "@mui/styles";

import { useStyles } from "../../styles/styles";
import { theme as Theme } from "../../theme";
import { navigateToUrl } from "../../utils";
import config from "../../config";
import { createVerificationSession, postLoginAttemp } from "../../services/api";
import { CvsRequestContext } from "../../context/RequestContext";
import { FailureMessage } from "../AuthenticateComponents/LoginFailure";
import { LoginContext } from "../../context/LoginContext";
import { useNavigate } from "react-router";
import STEPS from "../../pages/register/steps";
import {
  ACCOUNT_CREATION_FAILED,
  ACCOUNT_NOT_APPROVED,
  AUTHENTICATION_FAILED,
} from "../../constants";
import CvsButton from "../Button";

function ButtonText(props: {
  loading: boolean;
  classes: ClassNameMap;
  message?: string;
}) {
  if (props.loading) {
    return <CircularProgress className={props.classes.scanLoader} />;
  }
  if (props.message) {
    return <>Enroll now</>;
  }
  return <>Try again</>;
}

const Failure = ({
  skin,
  matchesSM,
  message,
  postToOidc = () => {},
  isLogin = false,
  setStep,
}: {
  skin: string;
  matchesSM: boolean;
  message?: string;
  postToOidc?: () => void;
  isLogin: boolean;
  setStep?: (e: any) => void;
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;
  const user = JSON.parse(localStorage.getItem("user") ?? "{}");
  const [loading, setLoading] = useState(false);
  const createVerification = async () => {
    const hasSSN = window.location.href.includes("bypassSsn=true");
    if (user?._id && user?.token) {
      navigateToUrl(user?.successUrl, user?.token);
      return;
    }
    setLoading(true);
    const payload = {
      ...config.clientConfig,
      productGroupId: "intergalactic",
    };
    const result: any = await createVerificationSession(payload);
    if (result?.token) {
      window.location.href = `/register?token=${result?.token}&skin=${skin}`;
    }
    setLoading(false);
  };

  const loginContext = useContext(LoginContext);
  const logUserFailureAttempt = async () => {
    const userData = {
      status: "failure",
      predictedGUID: loginContext.predictedGUID || "none",
      barcodeHash: loginContext.barcodeHash,
      error: true,
      errorMessage: loginContext.errorMessage,
    };

    const res = await postLoginAttemp(userData);
  };

  const requestContext = useContext(CvsRequestContext);
  useEffect(() => {
    if (isLogin) {
      logUserFailureAttempt();
    }
  }, []);

  useEffect(() => {
    if (requestContext.requestFromOIDC) {
      setTimeout(() => {
        postToOidc();
      }, 3000);
    }
  }, [requestContext]);
  return (
    <>
      <FailureMessage
        classes={classes}
        message={message}
        palette={palette}
        skin={skin}
        matchesSM={matchesSM}
      />
      <Grid style={{ marginBottom: 20 }}>
        {message === ACCOUNT_CREATION_FAILED ||
        message === AUTHENTICATION_FAILED ? (
          <>
            <CvsButton
              title="Try again"
              onClick={() =>
                message === AUTHENTICATION_FAILED
                  ? setStep?.(STEPS.SIGN_IN)
                  : createVerification()
              }
              skin={skin}
            />
            <CvsButton
              title="Provide Feedback"
              onClick={() => setStep?.(STEPS.FEEDBACK)}
              skin={skin}
            />
          </>
        ) : message === ACCOUNT_NOT_APPROVED ? (
          <>
            <CvsButton
              title="Register again"
              onClick={createVerification}
              skin={skin}
            />
            <CvsButton
              title="Return to home page"
              onClick={() => navigate("/")}
              skin={skin}
            />
          </>
        ) : (
          !requestContext.requestFromOIDC && (
            <Box>
              <CvsButton
                title={
                  <ButtonText
                    loading={loading}
                    classes={classes}
                    message={message}
                  />
                }
                onClick={createVerification}
                skin={skin}
              />

              {isLogin && (
                <CvsButton
                  title={<ButtonText loading={false} classes={classes} />}
                  onClick={() => {
                    navigate(0);
                  }}
                  skin={skin}
                />
              )}
            </Box>
          )
        )}
      </Grid>
    </>
  );
};

export default Failure;
