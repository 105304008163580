import { useState, useContext, useEffect } from "react";
import { useTheme, useMediaQuery } from "@mui/material";

import HomeModal from "../../components/Modal/homeModal";
import Header from "../../components/Header";
import STEPS from "./steps";
import { useNavigate } from "react-router-dom";
import useToast from "../../utils/useToast";
import { CvsRequestContext } from "../../context/RequestContext";
import { antispoofCheck } from "@privateid/cryptonets-web-sdk-alpha/dist/utils";
import { FailureTypeEnum, _renderChildren } from "../login_with_barcode";
import { LoginContext } from "../../context/LoginContext";
import { getUser } from "../../services/api";
import { ERROR, SUCCESS, getStatusFromUser } from "../../utils";

interface FaceLoginProps {
  theme: string;
  skin: string;
}

const FaceLogin = ({ theme, skin }: FaceLoginProps) => {
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [step, setStep] = useState(STEPS.START);
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));

  const requestContext = useContext(CvsRequestContext);

  const [failureMessage, setFailureMessage] = useState<any>(null);
  const [loginDataResult, setLoginDataResult] = useState<any>(null);
  const loginContext = useContext(LoginContext);
  const postToOidc = () => {
    if (requestContext.requestFromOIDC) {
      const form = document.createElement("form");
      form.method = "POST";
      form.action = `https://oidc.privateid.com/interaction/${requestContext.interactionUID}/login`;
      const params: any = loginDataResult
        ? {
          uuid: loginDataResult.PI.uuid,
          guid: loginDataResult.PI.guid,
        }
        : {};
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const hiddenField = document.createElement("input");
          hiddenField.type = "hidden";
          hiddenField.name = key;
          hiddenField.value = params[key];
          form.appendChild(hiddenField);
        }
      }
      document.body.appendChild(form);
      form.submit();
    }
  };

  const onLoginSuccess = async (loginData: any, loginImage: any) => {
    setLoginDataResult(loginData);
    if (loginData.PI.guid && loginData.PI.uuid) {
      loginContext.setPredictedGUID(loginData.PI.guid);
      const antiSpoofResult = await antispoofCheck(
        loginImage.data,
        loginImage.width,
        loginImage.height
      );
      if (antiSpoofResult.livenessCheck === 0) {
        const payload = {
          guid: loginData?.PI?.guid,
        };
        const data: any = await getUser(payload);
        if (data?.data?.level === ERROR) {
          loginContext.setErrorMessage("Authentication Failed - User not verified.")
          setStep(STEPS.FAILURE);
        }
        else {
          const userStatus = getStatusFromUser(data);
          if (userStatus === SUCCESS) {
            setStep(STEPS.SUCCESS);
            showToast("You have successfully authenticated", "success");
          } else {
            setStep(STEPS.FAILURE);
            loginContext.setErrorMessage("Authentication Failed - User not verified")
            setFailureMessage("Authentication Failed - User not verified");
            setStep(STEPS.FAILURE);
          }
        }
      } else {
        const ErrorMessage =
          antiSpoofResult.livenessCheck === 1
            ? "Authentication Failed - Liveness check (spoof attempt detected)"
            : "Authentication Failed - No Face Detected";
        loginContext.setErrorMessage(ErrorMessage);
        setFailureMessage(ErrorMessage);
        showToast(ErrorMessage, "error", 8);

        setStep(STEPS.FAILURE);
      }
    }
  };

  const onLoginFailure = async (type: FailureTypeEnum) => {
    const ErrorMessage =
      type === FailureTypeEnum.USER_NOT_ENROLLED
        ? "Authentication Failed - User Not Enrolled"
        : "Authentication Failed - No Face Detected";
    loginContext.setErrorMessage(ErrorMessage);
    setFailureMessage(ErrorMessage);
    showToast(ErrorMessage, "error", 10000);
    setStep(STEPS.FAILURE);
  };

  useEffect(() => {
    if (
      requestContext.requestAAL &&
      parseInt(requestContext.requestAAL) === 3
    ) {
      navigate("login_with_barcode");
    }
  }, []);

  const themeName = skin || "primary";



  return (
    <>
      {<Header theme={themeName} />}
      <div className="homePageWrapper">
        <HomeModal
          handleClose={() => {
            navigate("/");
          }}
          open={true}
          onFeedback={() => { }}
          showFeedback={false}
        >
          {_renderChildren({
            step,
            setStep,
            skin,
            theme,
            onLoginSuccess,
            onLoginFailure,
            matchesSM,
            postToOidc,
            failureMessage,
          })}
        </HomeModal>
      </div>
    </>
  );
};

export default FaceLogin;
