import React, { createContext, useMemo, useState } from "react";

export const CvsRequestContext = createContext({
  federationToken: "",
  setFederationToken: (federationToken: any) => {},
  privateID: "",
  setPrivateID: (privateID: any) => {},
  actionFlow: "",
  setActionFlow: (actionFlow: any) => {},
  productGroup: "",
  setProductGroup: (productGroup: any) => {},
  requestIAL: "",
  setRequestIAL: (requestIAL: any) => {},
  requestFAL: "",
  setRequestFAL: (requestFAL: any) => {},
  requestAAL: "",
  setRequestAAL: (requestAAL: any) => {},
  interactionUID: "",
  setInteractionUID: (interactionUID: any) => {},
  requestFromOIDC: false,
  setRequestFromOIDC: (requestFromOIDC: boolean) => {},
  requestSSN: false,
  setRequestSSN: (requestSSN: boolean) => {},
});

export enum ActionFlow {
  LOGIN = "login",
  FORGET_ME = "forgetMe",
  REGISTER = "register",
}

const CvsRequestContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [federationToken, setFederationToken] = useState<any>(null);
  const [privateID, setPrivateID] = useState<any>(null);
  const [actionFlow, setActionFlow] = useState<any>(null);
  const [productGroup, setProductGroup] = useState<any>(null);
  const [requestIAL, setRequestIAL] = useState<any>(null);
  const [requestFAL, setRequestFAL] = useState<any>(null);
  const [requestAAL, setRequestAAL] = useState<any>(null);
  const [interactionUID, setInteractionUID] = useState<any>(null);
  const [requestFromOIDC, setRequestFromOIDC] = useState<any>(false);
  const [requestSSN, setRequestSSN] = useState<any>(false);
  const values = {
    federationToken,
    setFederationToken,
    privateID,
    setPrivateID,
    actionFlow,
    setActionFlow,
    productGroup,
    setProductGroup,
    requestIAL,
    setRequestIAL,
    requestFAL,
    setRequestFAL,
    requestAAL,
    setRequestAAL,
    interactionUID,
    setInteractionUID,
    requestFromOIDC,
    setRequestFromOIDC,
    requestSSN, 
    setRequestSSN,
  };
  const memoValues = useMemo(() => values, [values]);
  return (
    <CvsRequestContext.Provider value={memoValues}>
      {children}
    </CvsRequestContext.Provider>
  );
};

export default CvsRequestContextProvider;
