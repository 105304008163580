import { Box, Grid, Typography } from "@mui/material";
import { useStyles, styles } from "../../styles/styles";
import { theme as Theme } from "../../theme";

import STEPS from "../../pages/register/steps";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { CameraAlt } from "@mui/icons-material";
import { ComponentsParameterInterface } from "../../interface";
import { BrowserInfo, detect } from "detect-browser";

const getRedirectUrl = (browser: string | undefined) => {
  switch (browser) {
    case "safari":
      return `https://support.apple.com/en-in/guide/mac-help/mchlf6d108da/mac#:~:text=In%20Safari%2C%20to%20allow%20a,click%20Websites%2C%20then%20select%20Camera.`;
    case "firefox":
      return "https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions";
    default:
      return `https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop`;
  }
};
const CameraPermissionFail = ({
  setStep,
  skin,
  matchesSM,
}: ComponentsParameterInterface) => {
  const classes = useStyles();
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;
  const browser = detect();
  const redirectLink = getRedirectUrl(browser?.name);
  // @ts-ignore
  const browserInfo: BrowserInfo = detect();
  const { os } = browserInfo;
  return (
    <>
      <Grid style={styles.cardGrid} className={classes.cardGridMobile}>
        <Box className={classes.allowCameraBox}>
          <CameraAlt />
        </Box>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2 }}
          color={palette?.[skin]?.listText}
          textAlign="center"
          fontWeight={700}
          fontSize={22}
        >
          Camera permission needed
        </Typography>
        <Typography
          component="p"
          textAlign={"center"}
          fontSize={16}
          fontWeight={500}
          color={"#333"}
          mt={2}
        >
          To update permissions, see the instructions{" "}
          <span
            onClick={() => window.open(redirectLink)}
            style={{
              color: palette[skin]?.main,
              fontWeight: 700,
              cursor: "pointer",
            }}
          >
            here
          </span>
          .
        </Typography>
      </Grid>
    {os !== "iOS" && os !== "Android OS" &&
      <Box className={classes.otherOptionsBottom}>
        <Typography
          component="p"
          textAlign={matchesSM ? "center" : "left"}
          fontSize={15}
          fontWeight={500}
          mt={2}
          onClick={() => {
            setStep(STEPS.SWITCH_DEVICE);
          }}
        >
          <PhoneIphoneIcon /> Switch to Mobile Device
        </Typography>
      </Box>
     }
    </>
  );
};

export default CameraPermissionFail;
