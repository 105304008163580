import React, { useState, useContext, useEffect } from "react";
import {
  Alert,
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useStyles, styles } from "../../styles/styles";
import STEPS from "../../pages/register/steps";
import ScanBackDocument from "../DocumentCamera/ScanBackDocument";
import shield from "../../assets/shield.png";
import { UserContext } from "../../context/UserContext";
import {
  closeCamera,
  updateUser,
  uploadDL,
} from "@privateid/cryptonets-web-sdk-alpha";
import { DLType } from "@privateid/cryptonets-web-sdk-alpha/dist/types";

import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import SpinnerLoader from "../SpinnerLoader";
import FaceCompareFrontDocument from "../DocumentCamera/FaceCompareFrontDocument";
import { cameraDelay } from "../../utils";
import { ELEMENT_ID } from "../../constants";
import { BrowserInfo, detect } from "detect-browser";
import CvsButton from "../Button";

export enum DlActionEnum {
  frontscan = "frontscan",
  backscan = "backscan",
}
const DLFaceCompare = ({
  setStep,
  onSuccess,
  skin,
}: {
  setStep: any;
  onSuccess: () => void;
  skin: string;
}) => {
  const classes = useStyles();
  const [isUserVerify, setIsUserVerify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isScanningFailed, setIsScanningFailed] = useState(false);
  const [opStatus, setOpStatus] = useState<number>();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const context = useContext(UserContext);

  const { id, enrollImageData, setPortraitConfScore, dlAction, setDlAction } =
    context;

  useEffect(() => {
    setTimeout(() => {
      setIsScanningFailed(true);
    }, cameraDelay);
  }, []);

  const onSuccessFrontScan = async (result: {
    croppedDocument: string;
    croppedMugshot: string;
    inputImage: string;
    portraitConfScore: number;
  }) => {
    const {
      inputImage,
      croppedDocument,
      croppedMugshot,
      portraitConfScore: compareScore,
    } = result;
    setIsLoading(true);
    setIsScanningFailed(false);
    setPortraitConfScore(compareScore);

    const uploadImageInput = await uploadDL({
      id,
      type: DLType.FRONTDLORIGINAL,
      image: inputImage,
    });
    const uploadCroppedDocumentImage = await uploadDL({
      id,
      type: DLType.FRONTDLCROPPED,
      image: croppedDocument,
    });
    const uploadCroppedMugshotImage = await uploadDL({
      id,
      type: DLType.FRONTDLHEADSHOT,
      image: croppedMugshot,
    });
    const govId = {
      portraitConfScore: compareScore,
    };

    await updateUser({
      id,
      // @ts-ignore
      attributes: { govId: govId },
    });
    if (
      uploadImageInput &&
      uploadCroppedDocumentImage &&
      uploadCroppedMugshotImage
    ) {
      await closeCamera(ELEMENT_ID);
      setTimeout(() => {
        setIsUserVerify(true);
      }, 2000);
      setTimeout(() => {
        setIsLoading(false);
        setIsUserVerify(false);
        setDlAction(DlActionEnum.backscan);
        setTimeout(() => {
          setIsScanningFailed(true);
        }, cameraDelay);
      }, 4000);
    }
  };

  const onBackSuccess = async ({
    barcodeData,
    croppedDocument,
    croppedBarcode,
  }: {
    barcodeData: any;
    croppedDocument: string;
    croppedBarcode: string;
  }) => {
    if (isLoading) return;
    setIsLoading(true);
    setIsScanningFailed(false);
    await uploadDL({
      id,
      type: DLType.BACKDLBARCODE,
      image: croppedBarcode,
    });

    if (croppedDocument) {
      await uploadDL({
        id,
        type: DLType.BACKDLORIGINAL,
        image: croppedDocument,
      });
    }

    await uploadDL({
      id,
      type: DLType.BARCODEJSON,
      barcode: JSON.stringify(barcodeData),
    });

    const govId = {
      firstName: barcodeData.firstName,
      lastName: barcodeData.lastName,
      dob: barcodeData.dateOfBirth,
      address: {
        addressLine1: barcodeData.streetAddress1,
        addressLine2: barcodeData.streetAddress2,
        city: barcodeData.city,
        state: barcodeData.state,
        zipCode: barcodeData.postCode,
        country: barcodeData.issuingCountry,
        idDocumentNumber: barcodeData.customerId,
      },
    };

    await updateUser({
      id,
      // @ts-ignore
      attributes: { govId: govId },
    });

    setTimeout(() => {
      setIsUserVerify(true);
    }, 2000);
    setTimeout(() => {
      setIsUserVerify(false);

      onSuccess?.();
    }, 4000);
  };

  const onCameraNotGranted = (e: boolean) => {
    if (!e) {
      setStep(STEPS.CAMERA_PERMISSION_FAIL);
    }
  };

  const onCameraFail = async () => {
    setStep(STEPS.SWITCH_DEVICE);
  };

  const onCameraNotFullHd = async () => {
    await closeCamera(ELEMENT_ID);
    setStep(STEPS.SWITCH_DEVICE);
  };

  // @ts-ignore
  const browserInfo: BrowserInfo = detect();
  const { os } = browserInfo;

  return (
    <>
      {/*{opStatus !== 0 && isScanningFailed && (*/}
      {/*  <Alert*/}
      {/*    severity="info"*/}
      {/*    onClick={async () => {*/}
      {/*      setStep(STEPS.SWITCH_DEVICE);*/}
      {/*      await closeCamera(ELEMENT_ID);*/}
      {/*    }}*/}
      {/*    className={classes.alertWrap}*/}
      {/*  >*/}
      {/*    You can try switching to other device.*/}
      {/*  </Alert>*/}
      {/*)}*/}
      <Grid
        style={styles.cardGrid}
        className={`cardGridMobile overflowUnset ${classes.cardSxMobile}`}
      >
        <Box position={"relative"}>
          <Box position={"relative"}>
            {isLoading && (
              <Box
                style={
                  {
                    ...styles.overlay,
                    top: matchesSM ? "45px" : "-5px",
                    height: matchesSM ? "88%" : "100%",
                  } as React.CSSProperties
                }
              >
                {isUserVerify ? (
                  <img
                    src={shield}
                    alt="shield"
                    style={styles.shield as React.CSSProperties}
                  />
                ) : (
                  <SpinnerLoader />
                )}
              </Box>
            )}

            {dlAction === DlActionEnum.backscan ? (
              <ScanBackDocument
                onSuccess={onBackSuccess}
                onReadyCallback={onCameraNotGranted}
                onCameraFail={onCameraFail}
                onCameraNotFullHd={onCameraNotFullHd}
                setOpStatus={(e: number) => setOpStatus(e)}
                setStep={setStep}
              />
            ) : (
              <FaceCompareFrontDocument
                onSuccess={onSuccessFrontScan}
                onReadyCallback={onCameraNotGranted}
                onFailCallback={(e) => null}
                onCameraFail={onCameraFail}
                enrollImageData={enrollImageData}
                setOpStatus={(e: number) => setOpStatus(e)}
                setStep={setStep}
              />
            )}
          </Box>
        </Box>
      </Grid>
      <Box className={classes.scanBottomBox}>
        {/* {os !== "iOS" && os !== "Android OS" && (
          <Box className={classes.otherDevice} pl={3} mb={1}>
            <Typography
              component="p"
              textAlign={"left"}
              fontSize={15}
              fontWeight={500}
              mt={0}
              onClick={() => {
                setStep(STEPS.SWITCH_DEVICE);
              }}
            >
              <PhoneIphoneIcon /> Switch to Mobile Device
            </Typography>
          </Box>
        )} */}
        <CvsButton
          title={"I do not have an ID Card"}
          onClick={() => {
            setStep(STEPS.PERSONAL_DETAILS);
          }}
          skin={skin}
        />
      </Box>
    </>
  );
};

export default DLFaceCompare;
