import { Box, Typography, useTheme, useMediaQuery, Stack } from "@mui/material";
import { useStyles, styles } from "./styles";
import React, { useEffect, useState } from "react";
import homeStyles from "../../../styles/Home.module.css";
import Camera from "../../Camera";
import shield from "../../../assets/shield.png";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import SpinnerLoader from "../../SpinnerLoader";

import useFaceLogin from "../../../hooks/useFaceLogin";
import { FailureTypeEnum } from "../../../pages/login_with_barcode";
import { BrowserInfo, detect } from "detect-browser";

export const LoginWrap = (props: any) => {
  const {
    matchesSM,
    showSuccess,
    hasNoCamera,
    doFaceLogin,
    onCameraFail,
    deleteMessage,
    faceLoginMessage,
    classes,
    onSwitchDevice,
  } = props;
  // @ts-ignore
  const browserInfo: BrowserInfo = detect();
  const { os } = browserInfo;
  return (
    <Box
      position={"relative"}
      padding={matchesSM ? "0px 10px" : "10px 10px"}
      mt={0}
      pr={"12px"}
    >
      <SuccessWrap showSuccess={showSuccess} />
      <>
        <div id="canvasInput" className={homeStyles.container}>
          {hasNoCamera ? (
            <Stack
              width={"100%"}
              height={"400px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography variant="h5" color={"#000000"}>
                No Camera Detected.
              </Typography>
              <Typography variant="subtitle1" color={"#000000"}>
                Please switch device.
              </Typography>
            </Stack>
          ) : (
            <Camera
              onReadyCallback={doFaceLogin}
              onSwitchCamera={() => { }}
              onCameraFail={onCameraFail}
              message={(deleteMessage ?? faceLoginMessage) || faceLoginMessage}
              onWasmLoadFail={() => { }}
              canvasLayout={true}
            />
          )}
        </div>
        {os !== "iOS" && os !== "Android OS" &&
          <Box className={classes.otherOptions}>
            <Typography
              component="p"
              textAlign={matchesSM ? "center" : "left"}
              fontSize={15}
              fontWeight={500}
              mt={2}
              onClick={() => {
                onSwitchDevice();
              }}
            >
              <PhoneIphoneIcon /> Switch to Mobile Device
            </Typography>
          </Box>
        }
      </>
    </Box>
  );
};

export const SuccessWrap = ({ showSuccess }: any) =>
  showSuccess && (
    <Box style={styles.overlayCamera as React.CSSProperties}>
      {showSuccess ? (
        <img
          src={shield}
          alt="shield"
          style={styles.shield as React.CSSProperties}
        />
      ) : (
        <SpinnerLoader />
      )}
    </Box>
  );

const Login = ({
  setStep,
  onSuccess,
  onFailure,
  onSwitchDevice,
}: {
  onReadyCallback?: (e: boolean) => void;
  onSwitchCamera?: () => void;
  message?: string;
  setStep: React.Dispatch<React.SetStateAction<string>>;
  skin: string;
  onSuccess: (loginData: any, loginImage: any) => void;
  onFailure: (type: FailureTypeEnum) => void;
  onSwitchDevice: () => void;
}) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [showSuccess, setShowSuccess] = useState(false);
  const [hasNoCamera, setHasNoCamera] = useState(false);


  const onFaceNotFound = () => {
    onFailure(FailureTypeEnum.FACE_NOT_FOUND);
  }

  const onUserNotEnrolled = () => {
    onFailure(FailureTypeEnum.USER_NOT_ENROLLED);
  }

  const {
    doFaceLogin,
    faceLoginWithLivenessMessage: faceLoginMessage,
    faceLoginInputImageData,
    faceLoginData,
    setStopLogin,
  } = useFaceLogin(setShowSuccess, () => { }, onUserNotEnrolled, onFaceNotFound);

  useEffect(() => {
    if (faceLoginInputImageData && faceLoginData) {
      onSuccess(faceLoginData, faceLoginInputImageData);
      setShowSuccess(true);
    }
  }, [faceLoginInputImageData, faceLoginData]);

  const onCameraFail = async () => {
    setHasNoCamera(true);
  };

  const switchDevice = async () => {
    setStopLogin();
    setTimeout(async () => {
      onSwitchDevice();
    }, 500)

  }

  return (
    <>
      <LoginWrap
        matchesSM={matchesSM}
        showSuccess={showSuccess}
        hasNoCamera={hasNoCamera}
        doFaceLogin={doFaceLogin}
        onCameraFail={onCameraFail}
        deleteMessage={''}
        faceLoginMessage={faceLoginMessage}
        classes={classes}
        onSwitchDevice={switchDevice}
      />
    </>
  );
};
export default Login;
