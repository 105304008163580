import { headerVisible } from "../../theme";
import Header from "../../components/Header";
import HomeComponent from "../../components/HomeComponent";
import { useContext, useEffect } from "react";
import { CvsRequestContext } from "../../context/RequestContext";
import { useNavigate } from "react-router";
import config from "../../config";
import { createVerificationSession } from "../../services/api";
import bannerBottom from "../../assets/bannerBottom.png";
import { createSearchParams } from "react-router-dom";
import { Container, useMediaQuery, useTheme } from "@mui/material";

interface HomeProps {
  theme: string;
  skin: string;
}
const Home = ({ theme, skin }: HomeProps) => {
  const themeName = skin || "primary";
  const navigate = useNavigate();
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));

  const cvsQueryContext = useContext(CvsRequestContext);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const federationToken = urlParams.get("federationToken");
    const apiKey = urlParams.get("apiKey");
    const action = urlParams.get("actionFlow");
    const productGroup = urlParams.get("productGroup");
    const requestIAL = urlParams.get("requestIAL");
    const requestFAL = urlParams.get("requestFAL");
    const requestAAL = urlParams.get("requestAAL");
    const interactionUID = urlParams.get("interactionUID");
    const requestSSN = urlParams.get("requestSSN");

    cvsQueryContext.setFederationToken(federationToken);
    cvsQueryContext.setPrivateID(apiKey);
    cvsQueryContext.setActionFlow(action);
    cvsQueryContext.setProductGroup(productGroup);
    cvsQueryContext.setRequestIAL(requestIAL);
    cvsQueryContext.setRequestFAL(requestFAL);
    cvsQueryContext.setRequestAAL(requestAAL);
    cvsQueryContext.setInteractionUID(interactionUID);
    cvsQueryContext.setRequestSSN(requestSSN === "true");

    if (action === "login") {
      cvsQueryContext.setRequestFromOIDC(true);
      if (requestAAL === "1.0") {
        navigate("/login");
      } else if (requestAAL === "2.0") {
        navigate("/login");
      } else if (requestAAL === "3.0") {
        navigate("/login_with_barcode");
      }
    }

    if (action === "forgetMe") {
      cvsQueryContext.setRequestFromOIDC(true);
      navigate("/forget_me");
    }

    if (action === "register") {
      cvsQueryContext.setRequestFromOIDC(true);
      const payload = {
        ...config.clientConfig,
        productGroupId: "intergalactic",
      };

      const navigateToRegister = async () => {
        const result: any = await createVerificationSession(payload);
        const searchParam =
          requestSSN === "true"
            ? {
                token: result.token || "",
                IAL: requestIAL ?? "",
              }
            : {
                token: result.token || "",
                IAL: requestIAL ?? "",
                bypassSsn: true,
              };

        navigate({
          pathname: "/register",
          //@ts-ignore
          search: createSearchParams(searchParam).toString(),
        });
      };
      navigateToRegister();
    }
  }, []);

  return (
    <>
      {headerVisible?.includes(skin) && <Header theme={themeName} />}
      <Container maxWidth="lg" disableGutters={matchesSM}>
        <div className="homePageWrapper homeComponent homeComponentWrap">
          <HomeComponent theme={theme} skin={skin} />
        </div>
        {!matchesSM ? (
          <img src={bannerBottom} alt="BannerBottom" className="bannerBottom" />
        ) : null}
      </Container>
    </>
  );
};

export default Home;
