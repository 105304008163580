import { useState } from "react";
import { faceLogin } from "@privateid/cryptonets-web-sdk-alpha";
import { getStatusMessage } from "@privateid/cryptonets-web-sdk-alpha/dist/utils";

let showError = false;

const useFaceLoginWithLivenessCheck = (
  setShowSuccess: any = () => {},
  onSetStatus?: (e: number) => void,
  retryTimes = 3,
  isInitialPredict = true
) => {
  const [faceLoginWithLivenessMessage, setPredictMessage] = useState("");
  const [faceLoginInputImageData, setFaceLoginInputImageData] =
    useState<any>(null);
  const [faceLoginData, setPredictData] = useState<any>(null);
  let tries = 0;
  const faceLoginWithLiveness = async () => {
    // eslint-disable-next-line no-unused-vars
    const inputImage = await faceLogin(
      callback,
      {
        input_image_format: "rgba",
      },
      undefined,
      false,
      true
    );
    setFaceLoginInputImageData(inputImage);
  };

  const callback = async (result: any) => {
    if (result.status !== "WASM_RESPONSE") {
      faceLoginWithLiveness();
      return;
    }

    onSetStatus?.(result?.returnValue?.status);
    if (result?.returnValue?.error) {
      setPredictMessage("Please position your face in the center of the circle");
      return;
    }
    if (result.returnValue?.status === 0) {
      const { message, status } = result.returnValue;
      setPredictMessage("Valid Image");
      setPredictData({
        ...result?.returnValue,
        retryComplete: !isInitialPredict && tries === retryTimes,
      });
      setShowSuccess(result.returnValue);
    } else {
      const { message = "", status = -100 } = result.returnValue || {};
      const statusMessage = status === -1 || status === -100? "Please position your face in the center of the circle" : getStatusMessage(status);
      if (!showError) {
        showError = true;
        setPredictMessage(statusMessage);
        setTimeout(() => {
          showError = false;
        }, 2000);
      }

      if (tries !== retryTimes) {
        if (isInitialPredict || result.returnValue?.status === -1) {
          tries += 1;
        }

        faceLoginWithLiveness();
      } else {
        setShowSuccess(result.returnValue);
        setPredictData({
          ...result?.returnValue,
          retryComplete: !isInitialPredict && tries === retryTimes,
        });
        tries = 0;
      }
    }
  };

  const resetFaceLogin = (callFunc = true) => {
    setPredictData(undefined);
    setFaceLoginInputImageData(null);
    setPredictMessage("");
    if (callFunc) {
      faceLoginWithLiveness();
    }
  };

  return {
    faceLoginWithLiveness,
    faceLoginWithLivenessMessage,
    faceLoginInputImageData,
    faceLoginData,
    resetFaceLogin,
  };
};

export default useFaceLoginWithLivenessCheck;
