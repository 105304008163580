import { useContext, useRef, useState } from "react";
import { Box, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { Email } from "@mui/icons-material";
import { createUser } from "@privateid/cryptonets-web-sdk-alpha/dist/apiUtils";
import PhoneIcon from "@mui/icons-material/Phone";
import InputMask from "react-input-mask";
import { styles, useStyles } from "../../styles/styles";
import { theme as Theme } from "../../theme";
import { UserContext } from "../../context/UserContext";
import { createUserID } from "../../utils";
import STEPS from "../../pages/register/steps";
import useToast from "../../utils/useToast";
import { updateUserToken } from "../../services/api";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { registerInputsSchema } from "../../constants/schema";
import { useWasm } from "../../hooks";
import CvsButton from "../Button";

enum InputField {
  EMAIL = "email",
  PHONE = "phone",
}

const RegisterInputs = ({
  setStep,
  skin,
  matchesSM,
  setPrevStep,
}: {
  setStep: any;
  skin: string;
  matchesSM: boolean;
  setPrevStep: (e: string) => void;
}) => {
  const wasm = useWasm();
  const classes = useStyles();
  const mainTheme = Theme;
  const { showToast } = useToast();
  const [loader, setLoader] = useState(false);
  const [isDisable, setIsDisable] = useState<any>({
    phone: false,
    email: false,
  });
  const palette: { [key: string]: any } = mainTheme.palette;
  const [searchParams] = useSearchParams();
  const isBypassSsn = searchParams.get("bypassSsn");

  const ssn4Ref = useRef<HTMLFormElement | null>(null);
  const emailRef = useRef<any>(null);
  const phoneRef = useRef<any>(null);
  const context = useContext(UserContext);

  const { setPhoneNumber, setSSN4, setId, tokenParams, setEmail } = context;
  const validateEmail = async (email: string) => {
    return await registerInputsSchema
      .validate({
        email: email,
      })
      .then(() => true)
      .catch(() => false);
  };

  const [showPhoneError, setShowPhoneError] = useState({
    error: false,
    message: "",
  });

  const handleContinue = async (e?: any) => {
    e.preventDefault();
    const ialValue: any = searchParams.get("IAL");
    setPrevStep(STEPS.REGISTER_FORM);

    const phoneInput = phoneRef?.current?.value;
    const emailInput = emailRef?.current?.value || " ";
    const isValidateEmail = await validateEmail(emailInput);
    if (!isValidateEmail && !phoneInput) {
      showToast("Need at least one field entered.", "error");
      return;
    }
    if (!isValidateEmail && !phoneInput) {
      showToast("Enter valid email", "error");
      handleCheckEmailOnBlur({ traget: { value: emailInput } });
      return;
    }

    if (phoneInput?.split("-").join("").length !== 10 && !isValidateEmail) {
      handleCheckPhoneInput({ traget: { value: phoneInput } });
      showToast("Enter mobile number", "error");
      return;
    }

    setLoader(true);
    const inputSSN4 = ssn4Ref?.current?.value;
    setPhoneNumber(phoneInput);
    setEmail(emailRef?.current?.value);
    setSSN4(inputSSN4);
    const newID = await createUserID();
    setId(newID);

    if (isBypassSsn) {
      const result: any = await createUser({
        id: newID,
        userConsent: true,
        userConsentDate: dayjs().format("MM/DD/YYYY"),
        ssn4: "9991" || undefined,
        email: emailRef?.current?.value || undefined,
        phone: phoneInput || undefined,
        iAL: ialValue || undefined,
      } as any);

      await updateUserToken({ customerId: newID }, tokenParams);

      if (result.user) {
        setStep(STEPS.ENROLL);
      }
    } else {
      setStep(STEPS.REGISTER_FORM_SSN);
    }

    setLoader(false);
  };

  const [showEmailError, setShowEmailError] = useState({
    error: false,
    message: "",
  });

  const handleCheckPhoneInput = async (e: any) => {
    const phoneInput = e?.target?.value;
    registerInputsSchema
      .validate({
        phone: phoneInput?.split("-")?.join(""),
      })
      .catch((error) =>
        setShowPhoneError({ error: true, message: error?.message })
      );
  };

  const handleCheckEmailOnBlur = (e: any) => {
    const emailInput = e?.target?.value;
    registerInputsSchema
      .validate({
        email: emailInput,
      })
      .catch((error) =>
        setShowEmailError({ error: true, message: error?.message })
      );
  };

  enum FocusHandlerEnum {
    email = "email",
    phone = "phone",
  }

  const handleOnFocus = (handle: FocusHandlerEnum) => {
    switch (handle) {
      case FocusHandlerEnum.email:
        setShowEmailError({ error: false, message: "" });
        return;
      case FocusHandlerEnum.phone:
        setShowPhoneError({ error: false, message: "" });
        return;
      default:
        return;
    }
  };

  const handleCheckboxDisabling = (Field: InputField) => {
    setShowEmailError({ error: false, message: "" });
    setShowPhoneError({ error: false, message: "" });

    switch (Field) {
      case InputField.EMAIL:
        if (!isDisable.email) {
          emailRef.current.value = "";
        }
        setIsDisable({ ...isDisable, email: !isDisable.email });
        return;
      case InputField.PHONE:
        if (!isDisable.phone) {
          phoneRef.current.value = "";
        }
        setIsDisable({ ...isDisable, phone: !isDisable.phone });
        return;
      default:
    }
  };

  const [phoneInput, setPhoneInput] = useState("");

  return (
    <>
      <form onSubmit={handleContinue}>
        <Grid style={styles.cardGrid} className={classes.cardGridMobile}>
          <Typography
            component="p"
            textAlign={"center"}
            fontSize={16}
            fontWeight={700}
            lineHeight={1.5}
            mt={3}
            mb={2}
            className={classes.cardInnerHeading}
          >
            Please enter your personal details below.
          </Typography>
          <Box width={"100%"}>
            <Grid container>
              <InputMask
                mask={"999-999-9999"}
                value={phoneInput}
                maskChar={null}
                onBlur={handleCheckPhoneInput}
                onFocus={() => {
                  handleOnFocus(FocusHandlerEnum.phone);
                }}
                disabled={isDisable.phone}
                ref={phoneRef}
                onChange={(e) => {
                  setPhoneInput(e.target.value);
                }}
              >
                {
                  // @ts-ignore
                  () => {
                    return (
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Phone"
                        type="tel"
                        placeholder="Phone"
                        name="Phone"
                        InputProps={{
                          startAdornment: <PhoneIcon sx={{ pr: 1 }} />,
                          sx: { backgroundColor: "#fff" },
                        }}
                        color={showPhoneError.error ? "error" : "primary"}
                        helperText={
                          showPhoneError.error ? showPhoneError.message : ""
                        }
                        inputProps={{ maxLength: 12 }}
                        sx={{
                          "& .MuiFormHelperText-contained": {
                            color: "red",
                          },
                          mb: 0,
                          mt: 2,
                        }}
                      />
                    );
                  }
                }
              </InputMask>

              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={isDisable.phone}
                    onChange={() => {
                      handleCheckboxDisabling(InputField.PHONE);
                    }}
                  />
                }
                label="I do not have a mobile phone"
              /> */}
            </Grid>

            <TextField
              fullWidth
              id="outlined-basic"
              label="Email"
              type="email"
              placeholder="Email"
              name="Email"
              InputProps={{
                startAdornment: <Email sx={{ pr: 1 }} />,
                sx: { backgroundColor: "#fff" },
              }}
              inputRef={emailRef}
              onBlur={handleCheckEmailOnBlur}
              onFocus={() => {
                handleOnFocus(FocusHandlerEnum.email);
              }}
              color={showEmailError.error ? "error" : "primary"}
              helperText={showEmailError.error ? showEmailError.message : ""}
              sx={{
                "& .MuiFormHelperText-contained": {
                  color: "red",
                },
                mb: 0,
                mt: 2,
              }}
              disabled={isDisable.email}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={isDisable.email}
                  onChange={() => {
                    handleCheckboxDisabling(InputField.EMAIL);
                  }}
                />
              }
              label="I do not have a personal email address"
            /> */}
          </Box>
        </Grid>
        <Box mb={"52px"}>
          <CvsButton
            title={
              loader ? (
                <CircularProgress className={classes.scanLoader} />
              ) : (
                "Continue"
              )
            }
            onClick={handleContinue}
            skin={skin}
          />
        </Box>
      </form>
    </>
  );
};

export default RegisterInputs;
