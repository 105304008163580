const STEPS = {
  START: "START",
  REGISTER_CONSENT: "REGISTER_CONSENT",
  PRIVACY_CONSENT: "PRIVACY_CONSENT",
  CONSENT: "CONSENT",
  CONSENT_FAIL: "CONSENT_FAIL",
  REGISTER_FORM: "RESISTER_FORM",
  REGISTER_FORM_SSN: "REGISTER_FORM_SSN",
  CAMERA_PERMISSION_FAIL: "CAMERA_PERMISSION_FAIL",
  PRE_ENROLL: "PRE_ENROLL",
  ENROLL: "ENROLL",
  PRE_DRIVERLICENSE: "PRE_DRIVERLICENSE",
  DRIVERLICENSE: "DRIVERLICENSE",
  SWITCH_DEVICE: "SWITCH_DEVICE",
  ADDITIONAL_REQUIREMENTS: "ADDITIONAL_REQUIREMENTS",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  REQUEST_SSN: "REQUEST_SSN",
  REQUEST_ADDRESS: "REQUEST_ADDRESS",
  FEEDBACK: "FEEDBACK",
  PRE_DOCUMENT: "PRE_DOCUMENT",
  INSURANCE_CARD: "INSURANCE_CARD",
  PERSONAL_DETAILS: "PERSONAL_DETAILS",
  ADDRESS_DETAIL: "ADDRESS_DETAIL",
  INITIAL: "INITIAL",
  SIGN_IN: "SIGN_IN",
  ANIMATION: "ANIMATION",
  PRE_DOCUMENT_SCAN: "PRE_DOCUMENT_SCAN",
  BACK_DOCUMENT_SCAN: "BACK_DOCUMENT_SCAN",
  DL_ANIMATION: "DL_ANIMATION",
  LOGIN: "LOGIN"
};

export default STEPS;
