import { makeStyles } from "@mui/styles";
import { localThemes, theme as Theme } from "../../theme";

const mainTheme = Theme;
const palette: { [key: string]: any } = mainTheme.palette;
const skin = localThemes?.includes(window?.location?.search?.split("skin=")[1])
  ? window?.location?.search?.split("skin=")[1]
  : "primary";

export const useStyles = makeStyles((theme: any) => ({
  tooltipWrap: {
    position: "relative",
    "&:hover": {
      "& $tooltip": {
        display: "block",
        [theme.breakpoints.between("xs", "sm")]: {
          display: "none"
        }
      },
    },
  },
  tooltip: {
    backgroundColor: palette?.[skin]?.text,
    color: "#333",
    padding: "10px",
    borderRadius: "7px",
    fontSize: "15px",
    minWidth: "120px",
    maxWidth: "250px",
    lineHeight: "19px",
    position: "absolute",
    right: "-280px",
    top: "50%",
    transform: "translateY(-60%)",
    display: "none",
    "&::after": {
      content: "''",
      width: 0,
      height: 0,
      borderTop: "8px solid transparent",
      borderRight: `16px solid ${palette?.[skin]?.text}`,
      borderBottom: "8px solid transparent",
      position: "absolute",
      left: "-15px",
      top: "50%",
      transform: "translateY(-50%)",
    },
  },
  tooltipLeft: {
    right: "160px !important",
    zIndex: "9999",
    top: '-14px',
    transform: "unset",
    minWidth: "200px",
    "&::after": {
        right: "-15px",
        left: "unset",
        top: "25px",
        transform: "rotate(180deg)",
      },
  },
}));
