import React from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme 
} from "@mui/material";

import { useStyles, styles } from "../../../styles/styles";
import ScanBackDocument from "../../DocumentCamera/ScanBackDocument";

import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { BrowserInfo, detect } from "detect-browser";

const BackDocumentScanModular = ({
  onSuccess,
  onSwitchDevice = () => { },
}: {
  onSuccess: (backDocumentData: any) => void;
  onSwitchDevice?: () => void;
}) => {
  const classes = useStyles();
  // @ts-ignore
  const browserInfo: BrowserInfo = detect();
  const { os } = browserInfo;
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Grid
        style={styles.cardGrid}
        className={`cardGridMobile overflowUnset ${classes.cardSxMobile}`}
      >
        <Box position={"relative"}>
          <Box position={"relative"}>
            <ScanBackDocument
              onSuccess={onSuccess}
              onReadyCallback={() => { }}
              onCameraFail={() => { }}
              onCameraNotFullHd={() => { }}
            />
          </Box>
        </Box>
      </Grid>
      {/* <Box style={{ height: 84 }} className={classes.scanBottomBox}>
        {os !== "iOS" && os !== "Android OS" &&
          <Box className={classes.otherDevice} pl={3} mb={1}>
            <Typography
              component="p"
              textAlign={"left"}
              fontSize={15}
              fontWeight={500}
              mt={0}
              onClick={() => {
                onSwitchDevice();
              }}
            >
              <PhoneIphoneIcon /> Switch to Mobile Device
            </Typography>
          </Box>
        }
        <Typography
          component="p"
          textAlign="center"
          fontSize={14}
          fontWeight={700}
          mt={1}
          mb={2}
        >
          Place the BACK of your ID towards the camera
        </Typography>
      </Box> */}
    </>
  );
};

export default BackDocumentScanModular;
