import { Grid } from "@mui/material";
import SelfieEmoji from "../../../assets/selfie.png";
import { styles, useStyles } from "../../../styles/styles";
import CvsButton from "../../Button";
import STEPS from "../../../pages/login/steps";
import { useNavigate } from "react-router";
import { PreForgetHeading } from "../PreForgetMe";

interface PreLoginProps {
  theme: string;
  skin: string;
  setStep: (e: string) => void;
  IAL?: any;
}

const PreLogin = (props: PreLoginProps) => {
  const { skin, setStep, IAL } = props;
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        alignItems={"center"}
        flexDirection={"column"}
        style={styles.cardGrid}
        className={classes.cardGridMobile}
      >
        <PreForgetHeading>
          {IAL
            ? "Get ready to take a selfie and scan your driver’s license"
            : "Get ready to take a selfie"}
        </PreForgetHeading>
        <img src={SelfieEmoji} alt="scan" height={"200px"} />
      </Grid>
      <CvsButton
        title={"Continue"}
        onClick={() => {
          setStep(STEPS.LOGIN);
        }}
        skin={skin}
      />
      <CvsButton
        title={"Back to homepage"}
        onClick={() => {
          navigate("/");
        }}
        skin={skin}
      />
    </>
  );
};

export default PreLogin;
