import { Grid, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { styles, useStyles } from "../../../styles/styles";
import STEPS from "../../../pages/register/steps";
import { useNavigate } from "react-router";
import CvsButton from "../../Button";
import SelfieEmoji from "../../../assets/selfie.png";

interface VerifyAgeWithScanProps {
  theme: string;
  skin: string;
  setPrevStep: (e: string) => void;
  setStep: (e: string) => void;
}

const VerifyAgeWithScan = (props: VerifyAgeWithScanProps) => {
  const { skin, setStep, setPrevStep } = props;
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(muiTheme.breakpoints.down(450));
  const classes = useStyles();
  const navigate = useNavigate();
  const getMarginTop = () => {
    if (matchesXS) return 1;
    if (matchesSM) return 2.5;
    return undefined;
  };
  return (
    <>
      <Grid
        container
        alignItems={"center"}
        flexDirection={"column"}
        style={{ ...styles.cardGrid, display: "block" }}
        className={classes.cardGridMobile}
      >
        <Grid item>
          <Typography
            component="p"
            textAlign={"center"}
            fontSize={matchesXS ? "1.1em" : "1.3em"}
            fontWeight={700}
            mt={0}
            overflow={"auto"}
            px={matchesSM ? 4 : 5}
          >
            Take a selfie to verify your identity
          </Typography>
          <Typography
            component="p"
            textAlign={"center"}
            fontSize={"12px"}
            fontWeight={400}
            mb={1}
          >
            Additional information is also required.
          </Typography>
        </Grid>
        {matchesSM && (
          <img
            src={SelfieEmoji}
            alt="scan"
            width={"140px"}
            height={"140px"}
            className={classes.setfieImage}
          />
        )}
        <Grid item mt={1}>
          <Typography
            component="p"
            textAlign={"center"}
            variant="body2"
            sx={{ lineHeight: 1.5, opacity: 0.8 }}
            overflow={"auto"}
            px={2}
            fontSize={matchesSM ? "13px" : "15px"}
          >
            Click{" "}
            <Link
              onClick={() => setStep(STEPS.PRIVACY_CONSENT)}
              sx={{ cursor: "pointer" }}
            >
              here
            </Link>{" "}
            to learn how this process works.
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            component="p"
            textAlign={"center"}
            variant="body2"
            overflow={"auto"}
            sx={{ opacity: 0.8 }}
            fontSize={matchesSM ? "13px" : "15px"}
            mt={getMarginTop()}
            px={3}
          >
            By clicking the “Agree and continue” button below, you acknowledge
            that you are over twenty one (21) years of age, you read the Private
            ID{" "}
            <Link
              href="https://github.com/openinfer/PrivateIdentity/wiki/Privacy-Policy"
              onClick={() =>
                window.open(
                  "https://github.com/openinfer/PrivateIdentity/wiki/Privacy-Policy",
                  "_blank"
                )
              }
            >
              Privacy Policy
            </Link>{" "}
            and{" "}
            <Link
              href="https://github.com/openinfer/PrivateIdentity/wiki/Terms-of-Use"
              onClick={() =>
                window.open(
                  "https://github.com/openinfer/PrivateIdentity/wiki/Terms-of-Use",
                  "_blank"
                )
              }
            >
              Terms of Use
            </Link>
            , and you understand how your personal data will be processed in
            connection with your use of this identity verification and
            authentication service.
          </Typography>
        </Grid>
      </Grid>
      <CvsButton
        title="Agree and continue"
        onClick={() => {
          setStep(STEPS.REGISTER_CONSENT);
          setPrevStep(STEPS.PRE_ENROLL);
        }}
        skin={skin}
      />
      <CvsButton
        title="No, I do not consent"
        onClick={() => {
          navigate("/");
        }}
        skin={skin}
      />
    </>
  );
};

export default VerifyAgeWithScan;
