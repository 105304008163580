import {
  Grid,
  Typography,
} from "@mui/material";
import { styles, useStyles } from "../../../styles/styles";
import { nameMap } from "../../../theme";
import { navigateToUrl } from "../../../utils";
import { useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import CvsButton from "../../Button";

interface CannotVerifyProps {
  theme: string;
  skin: string;
  setStep: (e: string) => void;
  prevStep: string;
}

const CannotVerify = (props: CannotVerifyProps) => {
  const { skin, setStep, prevStep } = props;
  const classes = useStyles();
  const context = useContext(UserContext);
  return (
    <>
      <Grid style={styles.cardGrid} className={classes.cardGridMobile}>
        <Typography
          component="p"
          textAlign="center"
          fontSize={25}
          fontWeight={700}
          mt={4}
        >
          ARE YOU SURE <br />
          YOU WANT TO EXIT?
        </Typography>
        <Typography
          component="p"
          textAlign="center"
          fontSize={20}
          fontWeight={500}
          mt={7}
        >
          {nameMap[skin] || "Stations"} cannot verify your identity
          <br /> without your consent.
        </Typography>
      </Grid>
      <CvsButton
        title={"Back to consent"}
        onClick={() => setStep(prevStep)}
        skin={skin}
      />
      <CvsButton
        title={"Exit"}
        onClick={() => navigateToUrl("/", context?.tokenParams)}
        skin={skin}
      />
    </>
  );
};

export default CannotVerify;
