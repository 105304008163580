import {
  MenuItem,
  Select,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { switchCamera } from "@privateid/cryptonets-web-sdk-alpha";
import { useEffect, useState } from "react";
import useCamera, { setResolutionForIphoneCC } from "../../hooks/useCamera";
import useWasm from "../../hooks/useWasm";
import styles from "../../styles/Home.module.css";
import { isBackCamera, isIphoneCC } from "../../utils";
import useCameraPermissions from "../../hooks/useCameraPermissions";
import { useStyles } from "./styles";
import STEPS from "../../pages/register/steps";
import CanvasLayout from "./canvasLayout";
import { detect, OperatingSystem, BrowserInfo } from "detect-browser";
import InstructionsStepper from "./MobileStepper";

export const SWITCH_DEVICE = "Switch Device";
const Camera = ({
  children,
  currentAction,
  style,
  mode = "front",
  message,
  onReadyCallback = () => {},
  onSwitchCamera = () => {},
  onCameraFail = () => {},
  onWasmLoadFail = () => {},
  requireHD = false,
  isDocumentScan = false,
  onCameraNotFullHd = () => {},
  setStep,
  loader,
  canvasLayout,
  enrollOneFaProgress,
  hideInstructionOverlay = true,
}: any) => {
  //@ts-ignore
  const browserData: BrowserInfo = detect();
  const { os }: { os: OperatingSystem | null } = browserData;
  const { ready: wasmReady, wasmStatus } = useWasm();
  const { isCameraGranted } = useCameraPermissions(onReadyCallback);
  const elementId = "userVideo";
  const classes = useStyles();
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const { ready, init, device, devices } = useCamera(
    elementId,
    mode,
    requireHD,
    onCameraFail,
    isDocumentScan
  );

  const [deviceId, setDeviceId] = useState(device);
  const [finishSlider, setFinishSlider] = useState(hideInstructionOverlay);
  const isBack = isBackCamera(devices, deviceId || device) ?? mode === "back";
  const [devicesList] = useState(devices);
  useEffect(() => {
    if (!wasmReady && wasmStatus.isChecking) return;
    if (
      wasmReady &&
      !wasmStatus.isChecking &&
      wasmStatus.support &&
      finishSlider
    ) {
      if (!ready) {
        init();
        return;
      } else if (isCameraGranted && ready) {
        onReadyCallback(true);
        return;
      }
    }

    if (!wasmReady && !wasmStatus.isChecking && !wasmStatus.support) {
      onWasmLoadFail();
    }
  }, [wasmReady, ready, wasmStatus, finishSlider]);

  const handleSwitchCamera = async (e: any) => {
    if (e.target.value === SWITCH_DEVICE) {
      setStep(STEPS?.SWITCH_DEVICE);
      return;
    }
    setDeviceId(e.target.value);
    // @ts-ignore
    const { capabilities } = await switchCamera(null, e.target.value);
    if (isIphoneCC(capabilities)) {
      await setResolutionForIphoneCC();
    }
    setTimeout(() => {
      onSwitchCamera(true);
    }, 3000);
  };

  const finishInstructionSlider = () => {
    setFinishSlider(true);
  };
  return (
    <div
      className={styles.cameraContainer}
      style={{
        ...style,
        marginTop: !ready && matchesSM ? 45 : matchesSM ? 0 : -10,
        marginBottom: !ready && !isDocumentScan ? "0px" : 0,
        height: matchesSM ? "unset" : "unset",
      }}
    >
      {!ready ? (
        !hideInstructionOverlay ? (
          <div
            className="instructionOverlay"
            style={{ height: isDocumentScan ? "99%" : "" }}
          >
            <InstructionsStepper
              finishInstructionSlider={finishInstructionSlider}
              isDocumentScan={isDocumentScan}
            />
          </div>
        ) : (
          <div className="overlayLoader">
            <CircularProgress />
          </div>
        )
      ) : null}{" "}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {ready ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 2,
              width: "100%",
              justifyContent: "space-between",
            }}
            className="mainCameraSelectWrap"
          >
            {/* <label style={{ color: "#000", paddingRight: 5 }}>
              Select Camera:
            </label> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={"Switch Camera"}
              onChange={(e: any) => handleSwitchCamera(e)}
              className="cameraSelect"
            >
              <MenuItem value={"Switch Camera"}>Switch Camera</MenuItem>
              {(devicesList?.length ? devicesList : devices).map(
                (e: { label: string; value: string }, index: number) => {
                  return (
                    <MenuItem id={e.value} value={e.value} key={e.value}>
                      {e.label}
                    </MenuItem>
                  );
                }
              )}
              {os !== "iOS" && os !== "Android OS" && (
                <MenuItem value={SWITCH_DEVICE}>
                  Switch to Mobile Device
                </MenuItem>
              )}
            </Select>
          </div>
        ) : null}
      </div>
      {message && !canvasLayout && (
        <div className={styles.enrollDisplay}>
          <span> {message} </span>
        </div>
      )}
      {ready ? <div className={classes.documentBarCodeOverlay} /> : null}
      <div
        style={{
          position: "relative",
        }}
      >
        <video
          id="userVideo"
          className={`
                ${styles.cameraDisplay} 
                ${
                  isBack || isDocumentScan ? "" : styles.mirrored
                }  videoCamera`}
          muted
          autoPlay
          playsInline
        />
        {canvasLayout && (
          <>
            <CanvasLayout
              setStep={setStep}
              loader={loader}
              ready={ready}
              hideMessage={() => {}}
              enrollOneFaProgress={enrollOneFaProgress}
              message={message}
            />
          </>
        )}
      </div>
      {children}
    </div>
  );
};

export default Camera;
