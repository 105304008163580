/* eslint-disable */
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useContext } from "react";
import { updateUser } from "@privateid/cryptonets-web-sdk-alpha/dist/apiUtils";

import { useStyles, styles } from "../../../styles/styles";
import { theme as Theme } from "../../../theme";
import { UserContext } from "../../../context/UserContext";
import STEPS from "../../../pages/register/steps";
import { states } from "../../../utils";
import * as yup from "yup";
import CvsButton from "../../Button";

export const addressDetailsSchema = yup.object().shape({
  addressLine1: yup
    .string()
    .required("Home address (Street address 1) is required."),
  city: yup.string().required("City is required."),
  state: yup.string().required("State is required."),
  zipCode: yup.string().required("Zip Code is required."),
});
const AddressDetail = ({
  setStep,
  skin,
  matchesSM,
  setPrevStep,
  onSuccess,
}: {
  setStep: any;
  skin: string;
  matchesSM: boolean;
  setPrevStep: (e: string) => void;
  onSuccess: () => void;
}) => {
  const classes = useStyles();
  const mainTheme = Theme;
  const [formData, setFormData] = useState({});
  const [state, setState] = useState<any>();
  const palette: { [key: string]: any } = mainTheme.palette;
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);

  const onInputChange = (e: any) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const context = useContext(UserContext);

  const { id } = context;

  const handleContinue = async (e?: any) => {
    e?.preventDefault();
    try {
      const isFormValid = await addressDetailsSchema.isValid(
        { ...formData, state },
        {
          abortEarly: false, // Prevent aborting validation after first error
        }
      );
      if (isFormValid) {
        setLoading(true);
        setPrevStep(STEPS.REGISTER_FORM);
        const updateUserResult: any = await updateUser({
          id,
          // @ts-ignore
          attributes: {
            preferred: { address: { ...formData, state, country: "USA" } },
          },
        } as any);

        if (updateUserResult?._id) {
          await onSuccess?.();
        }
        setLoading(false);
      } else {
        // If form is not valid, check which fields are incorrect:
        addressDetailsSchema
          .validate({ ...formData, state }, { abortEarly: false })
          .catch((err) => {
            // Collect all errors in { fieldName: boolean } format:
            const errors = err.inner.reduce(
              (acc: any, error: { path: any; message: any }) => {
                return {
                  ...acc,
                  [error.path]: error.message,
                };
              },
              {}
            );
            // Update form errors state:
            setErrors(errors);
          });
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <form onSubmit={handleContinue}>
        <Grid
          style={styles.requestAddressCardGrid}
          className={classes.cardGridMobile}
        >
          <Typography
            component="p"
            textAlign={"center"}
            fontSize={16}
            fontWeight={700}
            lineHeight={1.5}
            mt={3}
            mb={2}
            className={classes.cardInnerHeading}
          >
            Please enter your personal details below.
          </Typography>
          <Box width={"100%"}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Home address (Street address 1)"
              type="text"
              color={errors?.["addressLine1"] ? "error" : "primary"}
              helperText={errors?.["addressLine1"]}
              placeholder="Your home address (Street address 1)"
              name="addressLine1"
              required={true}
              sx={{
                "& .MuiInputBase-input": {
                  backgroundColor: "#fff",
                },
                "& .MuiFormHelperText-contained": {
                  color: "red",
                },
                mt: 0,
                mb: 2,
              }}
              onChange={onInputChange}
            />
            <TextField
              fullWidth
              id="outlined-basic"
              label="Home address (Street address 2)"
              type="text"
              placeholder="Your home address (Street address 2)"
              name="addressLine2"
              onChange={onInputChange}
              color={"primary"}
              sx={{
                "& .MuiInputBase-input": {
                  backgroundColor: "#fff",
                },
                "& .MuiFormHelperText-contained": {
                  color: "red",
                },
                mt: 0,
                mb: 2,
              }}
            />
            <TextField
              fullWidth
              id="outlined-basic"
              label="Your city"
              type="text"
              required={true}
              placeholder="Your city"
              color={errors?.["city"] ? "error" : "primary"}
              helperText={errors?.["city"]}
              name="city"
              onChange={onInputChange}
              sx={{
                "& .MuiInputBase-input": {
                  backgroundColor: "#fff",
                },
                "& .MuiFormHelperText-contained": {
                  color: "red",
                },
                mt: 0,
                mb: 2,
              }}
            />
            <Grid container justifyContent={"space-between"}>
              <Grid item xs={7}>
                <Autocomplete
                  id="combo-box-demo"
                  options={states}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State or province"
                      sx={{
                        "& .MuiInputBase-root": {
                          backgroundColor: "#fff",
                        },
                        "& .MuiFormHelperText-contained": {
                          color: "red",
                        },
                        mt: 0,
                        mb: 2,
                      }}
                      helperText={errors?.["state"]}
                      color={errors?.["state"] ? "error" : "primary"}
                    />
                  )}
                  onChange={(i, e) => setState(e?.abbreviation)}
                />
              </Grid>
              <Grid item xs={5} pl={2}>
                <TextField
                  // fullWidth
                  id="outlined-basic"
                  label="ZIP Code"
                  type="text"
                  required={true}
                  color={errors?.["zipCode"] ? "error" : "primary"}
                  helperText={errors?.["zipCode"]}
                  placeholder="ZIP Code"
                  name="zipCode"
                  inputProps={{
                    maxLength: 7,
                  }}
                  onChange={onInputChange}
                  sx={{
                    "& .MuiInputBase-input": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiFormHelperText-contained": {
                      color: "red",
                    },
                    mt: 0,
                    mb: 2,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Box mb={"52px"}>
          <CvsButton
            skin={skin}
            title={
              loading ? (
                <CircularProgress className={classes.scanLoader} />
              ) : (
                "Continue"
              )
            }
            onClick={handleContinue}
          />
        </Box>
      </form>
    </>
  );
};

export default AddressDetail;
