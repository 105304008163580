import {
  Box,
  Grid,
  ListItem,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { useStyles, styles } from "../../styles/styles";
import { theme as Theme } from "../../theme";

import { useState } from "react";
import DelightIcon from "../../assets/delight.svg";
import FrustrationIcon from "../../assets/frustration.svg";
import { feedback } from "../../services/api";
import useToast from "../../utils/useToast";
import { useNavigate } from "react-router";
import { AdminEmail } from "../../utils";
import CvsButton from "../Button";

const Feedback = ({
  setStep,
  skin,
  matchesSM,
}: {
  setStep: any;
  skin: string;
  matchesSM: boolean;
}) => {
  const classes = useStyles();
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [textArea, setTextArea] = useState("");
  const [emoji, setEmoji] = useState("");
  const [loader, setLoader] = useState(false);
  const emojiColor = (currentEmoji: string, isImage?: boolean) => {
    if (isImage) {
      return emoji === currentEmoji
        ? "invert(59%) sepia(62%) saturate(5636%) hue-rotate(186deg) brightness(100%) contrast(103%)"
        : null;
    } else {
      return emoji === currentEmoji
        ? palette?.[skin]?.main
        : palette?.[skin]?.feedBack;
    }
  };
  const onSubmit = async () => {
    setLoader(true);
    const payload = {
      type: "email",
      email: `mailto:${AdminEmail}`,
      endpoint: "feedback",
      subject: "Feedback from: CVS Identity Check",
      message: `\n \nUrl: ${window.location.href} \n \nFeeling: ${emoji} \n \nMessage: ${textArea}`,
    };
    const result = await feedback(payload);
    // @ts-ignore
    if (result?.success) {
      showToast("Feedback submitted successfully", "success");
      navigate("/");
    }
    setLoader(false);
  };
  return (
    <>
      <Grid style={styles.cardGrid} className={classes.cardGridMobile}>
        <Typography
          component="p"
          textAlign={"center"}
          fontSize={18}
          fontWeight={700}
          lineHeight={1.5}
          mt={2}
          className={classes.cardInnerHeading}
        >
          How do you rate your user registration experience?
        </Typography>
        <Box className={classes.feedbackIconsWrap}>
          <ListItem
            className={classes.feedBackIcon}
            onClick={() => setEmoji("Delight")}
          >
            <img
              src={DelightIcon}
              alt=""
              className={classes.feedBackIconImage}
              style={{
                filter: emojiColor("Delight", true),
              }}
            />
            <Typography component="p" color={emojiColor("Delight")}>
              Delight
            </Typography>
          </ListItem>
          <ListItem
            className={classes.feedBackIcon}
            onClick={() => setEmoji("Happy")}
          >
            <InsertEmoticonIcon style={{ color: emojiColor("Happy") }} />
            <Typography component="p" color={emojiColor("Happy")}>
              Happy
            </Typography>
          </ListItem>
          <ListItem
            className={classes.feedBackIcon}
            onClick={() => setEmoji("Sad")}
          >
            <SentimentVeryDissatisfiedIcon
              style={{ color: emojiColor("Sad") }}
            />
            <Typography component="p" color={emojiColor("Sad")}>
              Sad
            </Typography>
          </ListItem>
          <ListItem
            className={classes.feedBackIcon}
            onClick={() => setEmoji("Frustration")}
          >
            <img
              src={FrustrationIcon}
              alt=""
              className={classes.feedBackIconImage}
              style={{
                filter: emojiColor("Frustration", true),
              }}
            />
            <Typography component="p" color={emojiColor("Frustration")}>
              Frustration
            </Typography>
          </ListItem>
        </Box>
        <Typography
          component="p"
          textAlign={"center"}
          fontSize={18}
          fontWeight={700}
          lineHeight={1.5}
          mt={1}
          mb={1}
          className={classes.cardInnerHeading}
        >
          Tell us how we can improve?
        </Typography>
        <TextareaAutosize
          minRows={8}
          className={classes.textArea}
          onChange={(e) => setTextArea(e?.target?.value)}
        />
      </Grid>
      <Grid>
        <CvsButton title="Send and continue" onClick={onSubmit} skin={skin} />
        <CvsButton
          title="Cancel"
          onClick={() => {
            navigate("/");
          }}
          skin={skin}
        />
      </Grid>
    </>
  );
};

export default Feedback;
