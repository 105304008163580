import { useEffect, useContext, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";

import { useStyles, styles } from "../../styles/styles";
import { theme as Theme } from "../../theme";
import { UserContext } from "../../context/UserContext";
import {
  getStatusFromUser,
  navigateToUrl,
  stopCamera,
  SUCCESS,
} from "../../utils";
import successThumb from "../../assets/success-yellow.gif";
import shield from "../../assets/shield.png";
import homeStyles from "../../styles/Home.module.css";
import config from "../../config";
import {
  createVerificationSession,
  getUser,
  postLoginAttemp,
} from "../../services/api";
import { useCameraPermissions } from "../../hooks";
import { useNavigate } from "react-router";
import useToast from "../../utils/useToast";
import Camera from "../Camera";
import { CvsRequestContext } from "../../context/RequestContext";
import useFaceLoginWithLivenessCheck from "../../hooks/useFaceLoginWithLiveness";
import { LoginContext } from "../../context/LoginContext";
import STEPS from "../../pages/register/steps";
import { ACCOUNT_CREATED, AUTHENTICATION_SUCCESSFUL } from "../../constants";
import CvsButton from "../Button";

const Success = ({
  skin,
  matchesSM,
  postToOidc = () => {},
  message,
  fromLogin,
  setStep,
}: {
  skin: string;
  matchesSM: boolean;
  postToOidc?: () => void;
  message?: string;
  fromLogin?: boolean;
  setStep?: (e: any) => void;
}) => {
  const { isCameraGranted } = useCameraPermissions();
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("user") ?? "{}");
  const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
  const [loading, setLoading] = useState(false);
  const [authenticateLoading, setAuthenticateLoading] = useState(false);
  const [isInitialPredict, setInitialPredict] = useState(true);
  const [isCameraScan, setIsCameraScan] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [isUserVerify, setIsUserVerify] = useState(false);
  const { showToast } = useToast();
  const navigate = useNavigate();
  const mainTheme = Theme;
  const context = useContext(UserContext);
  const palette: { [key: string]: any } = mainTheme.palette;
  const requestContext = useContext(CvsRequestContext);
  const loginContext = useContext(LoginContext);

  useEffect(() => {
    if (requestContext.requestFromOIDC) {
      setTimeout(() => {
        postToOidc();
      }, 3000);
    }
  }, [requestContext]);

  const postToAttempt = async () => {
    const userData = {
      status: "success",
      predictedGUID: loginContext.predictedGUID,
      barcodeHash: loginContext.barcodeHash,
      error: false,
    };
    if (loginContext.predictedGUID) {
      const res = await postLoginAttemp(userData);
    }
  };
  useEffect(() => {
    postToAttempt();
  }, []);

  const createVerification = async () => {
    if (user?._id && user?.token) {
      navigateToUrl(user?.successUrl, user?.token);
      return;
    }
    setLoading(true);
    const payload = {
      ...config.clientConfig,
      productGroupId: "intergalactic",
    };
    const result: any = await createVerificationSession(payload);
    if (result?.token) {
      window.location.href = `/register?token=${result?.token}&skin=${skin}`;
    }
    setLoading(false);
  };
  const handlePredictSuccess = async (result: any) => {
    switch (result.status) {
      case -100:
        setInitialPredict(false);
        return setIsCameraScan(true);
      case 0: {
        localStorage.setItem("uuid", JSON.stringify(result?.PI?.uuid || {}));
        const payload = {
          guid: result?.PI?.guid,
        };
        const data: any = await getUser(payload);
        if (data?.orchestrationStatus === "Not Initiated") {
          showToast(data?.orchestrationStatus, "error");
          return createVerification();
        } else {
          setIsUserVerify(true);
          localStorage.setItem("user", JSON.stringify(data || {}));
          nextStep(data);
          stopCamera();
        }
        return false;
      }
      default: {
        showToast(result?.message, "error");
        return createVerification();
      }
    }
  };
  const retryTimes = isCameraScan ? 12 : 5;

  const { faceLoginWithLiveness, faceLoginWithLivenessMessage } =
    useFaceLoginWithLivenessCheck(
      handlePredictSuccess,
      undefined,
      retryTimes,
      isInitialPredict
    );

  const nextStep = async (userParam: any) => {
    const user = userParam || JSON.parse(localStorage.getItem("user") ?? "{}");
    if (!user._id) return;
    const userStatus = getStatusFromUser(user);
    setTimeout(async () => {
      setIsUserVerify(false);
      stopCamera();
      if (userStatus === SUCCESS) {
        showToast("Logged in Successfully", "success");
        const userData = {
          status: "success",
          predictedGUID: loginContext.predictedGUID,
          barcodeHash: loginContext.barcodeHash,
          error: false,
        };
        const res = await postLoginAttemp(userData);
      } else {
        showToast(
          "You have not successfully completed your verification",
          "error"
        );
        const userData = {
          status: "failure",
          predictedGUID: loginContext.predictedGUID || "none",
          barcodeHash: loginContext.barcodeHash,
          error: true,
          errorMessage: "Authentication failed - Verification not complete",
        };
        const res = await postLoginAttemp(userData);
      }
      navigate("/");
    }, 2000);
  };

  const onSignInClick = async () => {
    navigate("/signin");
  };
  useEffect(() => {
    setTimeout(() => {
      const status = getStatusFromUser(window.localStorage.getItem("user"));
      const { successUrl } = context.verificationSession;
      if (successUrl && status === SUCCESS) {
        window.location.href = successUrl;
      }
    }, 2000);
  }, []);
  return (
    <>
      {isCameraScan ? (
        <>
          <Box
            position={"relative"}
            padding={"10px 10px"}
            mt={0}
            mb={1}
            pr={"12px"}
          >
            {isUserVerify && (
              <Box style={styles.overlayCamera as React.CSSProperties}>
                <img
                  src={shield}
                  alt="shield"
                  style={styles.shield as React.CSSProperties}
                />
              </Box>
            )}
            <div id="canvasInput" className={homeStyles.container}>
              <Camera
                onReadyCallback={faceLoginWithLiveness}
                onSwitchCamera={faceLoginWithLiveness}
                message={faceLoginWithLivenessMessage}
                style={{ height: "unset" }}
              ></Camera>
            </div>
          </Box>
        </>
      ) : (
        <>
          <Grid
            style={{ ...styles.cardGrid, backgroundColor: "#FFE97D" }}
            className={classes.cardGridMobile}
          >
            {isScanning && (
              <div style={{ display: "none" }}>
                <Camera
                  onReadyCallback={faceLoginWithLiveness}
                  onSwitchCamera={faceLoginWithLiveness}
                  style={{ height: "unset" }}
                ></Camera>
              </div>
            )}
            <img
              src={successThumb}
              alt=""
              className={classes.checkIcon}
              width={matchesSM ? "100%" : 400}
            />
            <Typography
              component="p"
              textAlign={"center"}
              fontSize={25}
              fontWeight={500}
              lineHeight={1.5}
              mt={2}
              className={classes.cardInnerHeading}
              px={matchesSM ? 5 : ""}
            >
              {message ??
                "Congratulations! You have been successfully verified."}
            </Typography>
            {uuid?.length && (
              <Typography
                component="p"
                textAlign={"center"}
                fontSize={15}
                fontWeight={500}
                lineHeight={1.5}
                mt={0}
                className={classes.cardInnerHeading}
              >
                UUID : {uuid}
              </Typography>
            )}
          </Grid>

          <Grid style={{ marginBottom: 20 }}>
            {message === ACCOUNT_CREATED ? (
              <>
                <CvsButton
                  title="Get an ExtraCare card"
                  onClick={() => navigate("/")}
                  skin={skin}
                />
                <CvsButton
                  title="Connect my prescriptions"
                  onClick={() => navigate("/")}
                  skin={skin}
                />
              </>
            ) : message === AUTHENTICATION_SUCCESSFUL ? (
              <>
                <CvsButton
                  title="Return to home page"
                  onClick={() => navigate("/")}
                  skin={skin}
                />
                <CvsButton
                  title="Provide feedback"
                  onClick={() => setStep?.(STEPS.FEEDBACK)}
                  skin={skin}
                />
              </>
            ) : (
              !requestContext.requestFromOIDC && (
                <Box>
                  {fromLogin ? (
                    <CvsButton
                      title={
                        loading ? (
                          <CircularProgress className={classes.scanLoader} />
                        ) : (
                          "Continue"
                        )
                      }
                      onClick={() => navigate("/")}
                      skin={skin}
                    />
                  ) : (
                    <CvsButton
                      title={loading ? (
                        <CircularProgress className={classes.scanLoader} />
                      ) : (
                        "Start again"
                      )}
                      onClick={createVerification}
                      skin={skin}
                    />
                  )}
                </Box>
              )
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default Success;
