import { Button, Typography } from "@mui/material";
import { styles, useStyles } from "../../styles/styles";
import { theme as Theme } from "../../theme";

interface ButtonProps {
  onClick: () => void;
  skin: string;
  title: any;
}

const CvsButton = (props: ButtonProps) => {
  const classes = useStyles();
  const { skin, onClick, title } = props;
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;
  return (
    <>
      <Button
        variant="contained"
        color={"inherit"}
        style={styles.continueButton}
        className={classes.continueButton}
        onClick={onClick}
        type="submit"
      >
        <Typography
          component="p"
          color={palette?.[skin]?.secondaryColor}
          textAlign="center"
          fontWeight={600}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          textTransform="capitalize"
          fontSize={14}
        >
          {title}
        </Typography>
      </Button>
    </>
  );
};

export default CvsButton;
