import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router";

import shield from "../../assets/shield.png";
import { styles } from "../../pages/authenticate/styles";
import homeStyles from "../../styles/Home.module.css";
import { ERROR, getStatusFromUser, SUCCESS } from "../../utils";
import { getUser } from "../../services/api";
import HomeModal from "../../components/Modal/homeModal";
import useToast from "../../utils/useToast";
import Camera from "../../components/Camera";
import Success from "../../components/Success";
import Failure from "../../components/Failure";
import useFaceLoginWithLivenessCheck from "../../hooks/useFaceLoginWithLiveness";
import STEPS from "../../pages/register/steps";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import FullWidthTabs from "../OptionsTab";
import { useStyles } from "../AuthenticateComponents/Login/styles";
import Feedback from "../Feedback";
import { LoginContext } from "../../context/LoginContext";
import { antispoofCheck } from "@privateid/cryptonets-web-sdk-alpha/dist/utils";
import { BrowserInfo, detect } from "detect-browser";
import {
  ACCOUNT_NOT_APPROVED,
  AUTHENTICATION_FAILED,
  AUTHENTICATION_SUCCESSFUL,
  ELEMENT_ID,
} from "../../constants";
import PreDocumentScan from "../AuthenticateComponents/PreDocumentScan";
import BackDocumentScanModular from "../AuthenticateComponents/BackDocumentScan";
import { closeCamera } from "@privateid/cryptonets-web-sdk-alpha";

let count = 0;

interface SignInWithMessageProps {
  theme?: string;
  skin: any;
  onModalVisible: (e: boolean) => void;
  withBarcode: boolean;
}

const SignInWithMessage = ({
  skin,
  onModalVisible,
  withBarcode,
}: SignInWithMessageProps) => {
  const { showToast } = useToast();
  const [isInitialPredict, setInitialPredict] = useState(!withBarcode);
  const [message, setMessage] = useState("");
  const [step, setStep] = useState(withBarcode ? STEPS.SIGN_IN : STEPS.INITIAL);
  const [status, setStatus] = useState<any>();
  const [isUserVerify, setIsUserVerify] = useState(false);
  const navigate = useNavigate();
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const user = JSON.parse(localStorage.getItem("user") ?? "{}");
  const retryTimes = step === STEPS.SIGN_IN ? 50 : 5;
  // @ts-ignore
  const browserInfo: BrowserInfo = detect();
  const { os } = browserInfo;
  const classes = useStyles();
  const loginContext = useContext(LoginContext);

  useEffect(() => {
    if (!isInitialPredict) {
      onModalVisible(!isInitialPredict);
    }
  }, [isInitialPredict]);

  useEffect(() => {
    if (user?._id) {
      closeCamera(ELEMENT_ID);
    }
  }, [user]);

  const handleFailure = async (message = "", systemMessage = "") => {
    loginContext.setErrorMessage(systemMessage || message);
    setMessage(message);
    count = 0;
    showToast(message, "error");
    await closeCamera(ELEMENT_ID);
    localStorage.removeItem("user");
    resetFaceLogin(false);
    setStep(STEPS.FAILURE);
  };

  const handleSuccess = () => {
    setTimeout(() => {
      setStep(STEPS.SUCCESS);
    }, 300);
    setMessage(AUTHENTICATION_SUCCESSFUL);
    showToast(AUTHENTICATION_SUCCESSFUL, "success");
  };

  const nextStep = (userParam: any) => {
    const user = userParam || JSON.parse(localStorage.getItem("user") ?? "{}");
    if (!user._id) return;
    const userStatus = getStatusFromUser(user);
    setIsUserVerify(false);
    closeCamera(ELEMENT_ID);
    setInitialPredict(false);
    if (userStatus === SUCCESS) {
      loginContext.setPredictedGUID(user?.guid);
      if (withBarcode) {
        setStep(STEPS.BACK_DOCUMENT_SCAN);
      } else {
        handleSuccess();
      }
    } else {
      handleFailure(ACCOUNT_NOT_APPROVED);
    }
  };

  const onSetStatus = (e: number) => {
    setStatus(e);
  };

  const onFeedback = () => {
    setStep(STEPS.FEEDBACK);
  };

  const checkAntiSpoofImage = async (faceData: any, imageData: any) => {
    const antiSpoofResult: { livenessCheck: number } = await antispoofCheck(
      imageData.data,
      imageData.width,
      imageData.height
    );
    if (!isNaN(antiSpoofResult?.livenessCheck)) {
      count = 0;
      handlePredictSuccess(faceData);
    } else {
      if (faceData.retryComplete || count >= 50) {
        const ErrorMessage =
          antiSpoofResult?.livenessCheck === 1
            ? "Authentication Failed - Liveness check (spoof attempt detected)"
            : "Authentication Failed - No Face Detected";
        handleFailure(AUTHENTICATION_FAILED, ErrorMessage);
      } else {
        if (count === 3 && step === STEPS.INITIAL) {
          setStep(STEPS.SIGN_IN);
          setInitialPredict(false);
        } else {
          count++;
          await resetFaceLogin();
        }
      }
    }
  };
  const handlePredictSuccess = async (result: any) => {
    switch (result?.status) {
      case 0: {
        localStorage.setItem("uuid", JSON.stringify(result?.PI?.uuid || {}));
        const payload = {
          guid: result?.PI?.guid,
        };
        const data: any = await getUser(payload);
        if (data?.data?.level === ERROR) {
          handleFailure(AUTHENTICATION_FAILED);
        } else {
          setIsUserVerify(true);
          localStorage.setItem("user", JSON.stringify(data || {}));
          nextStep(data);
        }
        break;
      }
      default: {
        if (step === STEPS.INITIAL) {
          setInitialPredict(false);

          setStep(STEPS.SIGN_IN);
        } else {
          handleFailure(AUTHENTICATION_FAILED);
        }
        break;
      }
    }
  };

  const onClose = () => {
    closeCamera(ELEMENT_ID);
    setTimeout(() => {
      navigate("/");
    }, 50);
  };

  const {
    faceLoginWithLiveness,
    faceLoginWithLivenessMessage,
    faceLoginData,
    faceLoginInputImageData,
    resetFaceLogin,
  } = useFaceLoginWithLivenessCheck(
    () => {},
    onSetStatus,
    retryTimes,
    isInitialPredict
  );

  useEffect(() => {
    if (faceLoginData && faceLoginInputImageData) {
      if (faceLoginData.status === 0) {
        checkAntiSpoofImage(faceLoginData, faceLoginInputImageData);
      } else if (
        isInitialPredict ||
        (!isInitialPredict && faceLoginData.retryComplete)
      ) {
        handlePredictSuccess(faceLoginData);
      }
    }
  }, [
    faceLoginData?.retryComplete,
    faceLoginData?.status,
    faceLoginInputImageData,
  ]);

  const onSwitchDevice = () => setStep(STEPS.SWITCH_DEVICE);
  const _renderChildren = () => {
    switch (step) {
      case STEPS.SIGN_IN:
        return (
          <Box position={"relative"} padding={"10px 10px"} pr={"12px"}>
            {isUserVerify && (
              <Box style={styles.overlayCamera as React.CSSProperties}>
                <img
                  src={shield}
                  alt="shield"
                  style={styles.shield as React.CSSProperties}
                />
              </Box>
            )}
            <div id="canvasInput" className={homeStyles.container}>
              <Camera
                onReadyCallback={faceLoginWithLiveness}
                onSwitchCamera={faceLoginWithLiveness}
                message={faceLoginWithLivenessMessage}
                canvasLayout={true}
                setStep={setStep}
              ></Camera>
            </div>
            {/* {os !== "iOS" && os !== "Android OS" && (
              <Box className={classes.otherOptions}>
                <Typography
                  component="p"
                  textAlign={matchesSM ? "center" : "left"}
                  fontSize={15}
                  fontWeight={500}
                  mt={2}
                  onClick={() => {
                    closeCamera(ELEMENT_ID);
                    setTimeout(() => {
                      setStep(STEPS.SWITCH_DEVICE);
                    }, 50);
                  }}
                >
                  <PhoneIphoneIcon /> Switch to Mobile Device
                </Typography>
              </Box>
            )} */}
            {/* <Box>
              <Typography
                align="center"
                fontSize={14}
                fontWeight={700}
                mt={1}
                mb={1}
              >
                Please look at the camera to login
              </Typography>
            </Box> */}
          </Box>
        );
      case STEPS.SUCCESS:
        return (
          <Success
            matchesSM={matchesSM}
            skin={skin}
            message={message}
            fromLogin={true}
            setStep={setStep}
          />
        );
      case STEPS.FAILURE:
        return (
          <Failure
            matchesSM={matchesSM}
            skin={skin}
            message={message}
            isLogin={true}
            setStep={setStep}
          />
        );
      case STEPS.SWITCH_DEVICE:
        return <FullWidthTabs setStep={setStep} stopApi={true} />;
      case STEPS.FEEDBACK:
        return (
          <Feedback
            matchesSM={matchesSM}
            setStep={() => setStep(STEPS.SIGN_IN)}
            skin={skin}
          />
        );
      case STEPS.PRE_DOCUMENT_SCAN:
        return <PreDocumentScan skin={skin} setStep={setStep} />;
      case STEPS.BACK_DOCUMENT_SCAN:
        return (
          <BackDocumentScanModular
            onSuccess={handleSuccess}
            onSwitchDevice={onSwitchDevice}
          />
        );

      default:
        return (
          <Box
            position={"relative"}
            textAlign={"center"}
            padding={"10px 10px"}
            mt={3}
            pr={"12px"}
          >
            <CircularProgress />
          </Box>
        );
    }
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={matchesSM ? 12 : 6}>
        {isInitialPredict && (
          <div
            id="canvasInput"
            className={homeStyles.container}
            style={{ display: "none" }}
          >
            <Camera
              onReadyCallback={faceLoginWithLiveness}
              onSwitchCamera={faceLoginWithLiveness}
            ></Camera>
          </div>
        )}
        <HomeModal
          showFeedback={true}
          step={step}
          onFeedback={onFeedback}
          handleClose={onClose}
          open={!isInitialPredict}
          removePedding={true}
        >
          {_renderChildren()}
        </HomeModal>
      </Grid>
    </Grid>
  );
};

export default SignInWithMessage;
