import React, { createContext, useMemo, useState } from "react";

const defaultUserStatus = {
  requestSSN9: false,
  requestScanID: false,
  requestResAddress: false,
};

export const UserContext = createContext({
  id: "",
  setId: (id: string) => {},
  ssn4: "",
  setSSN4: (ssn4: string) => {},
  ssn9: "",
  setSSN9: (ssn9: string) => {},
  phoneNumber: "",
  email: "",
  setEmail: (email: string) => {},
  setPhoneNumber: (phone: string) => {},
  barcodeData: "",
  setBarcodeData: (barcode: string) => {},
  uuid: "",
  setUUID: (uuid: string) => {},
  guid: "",
  setGUID: (guid: string) => {},
  currentStatus: "",
  setCurrentStatus: (status: any) => {},
  successURL: "",
  setSuccessURL: (successURL: string) => {},
  failURL: "",
  setFailURL: (failURL: string) => {},
  userStatus: defaultUserStatus,
  setUserStatus: (userStatus: any) => {},
  verifyAttempts: 0,
  setVerifyAttempts: (userStatus: number) => {},
  verificationSession: {
    successUrl: "",
    failureUrl: "",
  },
  setVerificationSession: (verificationSession: any) => {},
  enrollImageData: null,
  setEnrollImageData: (enrollImageData: any) => {},
  portraitConfScore: 0,
  setPortraitConfScore: (portraitConfScore: any) => {},
  isWasmLoaded: false,
  setIsWasmLoaded: (isWasmLoaded: boolean) => {},
  tokenParams: "",
  setTokenParams: (tokenParams: string) => {},
  dlAction: "",
  setDlAction: (dlAction: string) => {},
});

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [id, setId] = useState("");
  const [ssn4, setSSN4] = useState("");
  const [ssn9, setSSN9] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [barcodeData, setBarcodeData] = useState("");
  const [uuid, setUUID] = useState("");
  const [guid, setGUID] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [successURL, setSuccessURL] = useState("");
  const [failURL, setFailURL] = useState("");
  const [userStatus, setUserStatus] = useState(defaultUserStatus);
  const [verifyAttempts, setVerifyAttempts] = useState(0);
  const [verificationSession, setVerificationSession] = useState<any>({});
  const [enrollImageData, setEnrollImageData] = useState<any>(null);
  const [portraitConfScore, setPortraitConfScore] = useState<any>(null);
  const [isWasmLoaded, setIsWasmLoaded] = useState(false);
  const [tokenParams, setTokenParams] = useState("");
  const [dlAction, setDlAction] = useState("");
  const values = {
    id,
    setId,
    ssn4,
    setSSN4,
    ssn9,
    setSSN9,
    phoneNumber,
    setPhoneNumber,
    email,
    setEmail,
    barcodeData,
    setBarcodeData,
    uuid,
    setUUID,
    guid,
    setGUID,
    currentStatus,
    setCurrentStatus,
    successURL,
    setSuccessURL,
    failURL,
    setFailURL,
    setUserStatus,
    userStatus,
    verifyAttempts,
    setVerifyAttempts,
    verificationSession,
    setVerificationSession,
    enrollImageData,
    setEnrollImageData,
    portraitConfScore,
    setPortraitConfScore,
    isWasmLoaded,
    setIsWasmLoaded,
    tokenParams,
    setTokenParams,
    dlAction,
    setDlAction,
  };
  const memoValues = useMemo(() => values, [values]);
  return (
    <UserContext.Provider value={memoValues}>{children}</UserContext.Provider>
  );
};

export default UserContextProvider;
