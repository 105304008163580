import {
  Grid,
  Typography,
} from "@mui/material";
import { styles, useStyles } from "../../../styles/styles";
import IdImage from "../../../assets/hand-holding-card.png";
import STEPS from "../../../pages/register/steps";
import CvsButton from "../../Button";

interface InsuranceCardProps {
  theme: string;
  skin: string;
  setStep: (e: string) => void;
}

export const HeadingWrap = ({ classes, children }: any) => (
  <Typography
    component="p"
    textAlign={"center"}
    fontSize={16}
    fontWeight={700}
    mt={2}
    className={classes.cardInnerHeading}
    letterSpacing={1}
  >
    {children}
  </Typography>
);

const InsuranceCard = (props: InsuranceCardProps) => {
  const { skin, setStep } = props;
  const classes = useStyles();
  return (
    <>
      <Grid style={styles.cardGrid} className={classes.cardGridMobile}>
        <HeadingWrap classes={classes}>
          Get ready to scan your
          <br /> Health insurance card
        </HeadingWrap>
        <img src={IdImage} alt="" className={classes.healthCard} />
      </Grid>
      <CvsButton
        skin={skin}
        title="Continue"
        onClick={() => {
          setStep(STEPS.DRIVERLICENSE);
        }}
      />
      <CvsButton
        skin={skin}
        title="I do not have my Card"
        onClick={() => {
          setStep(STEPS.PERSONAL_DETAILS);
        }}
      />
    </>
  );
};

export default InsuranceCard;
