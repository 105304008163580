import { useState, useContext, useEffect } from "react";
import { useTheme, useMediaQuery, Container, Grid } from "@mui/material";

import HomeModal from "../../components/Modal/homeModal";
import Header from "../../components/Header";
import STEPS from "./steps";
import FullWidthTabs from "../../components/OptionsTab";
import RegisterInputs from "../../components/Register";
import Enroll from "../../components/Enroll";
import DatabaseConsent from "../../components/SignupComponents/DatabaseConsent";
import CannotVerify from "../../components/SignupComponents/CannotVerify";
import VerifyAgeWithScan from "../../components/SignupComponents/VerifyAgeWithScan";
import DLScan from "../../components/DLScanning/DLFaceCompare";
import CameraPermissionFail from "../../components/CameraPermissionFail";
import { useNavigate, useSearchParams } from "react-router-dom";
import Success from "../../components/Success";
import { UserContext } from "../../context/UserContext";
import AdditionalRequirements from "../../components/AdditionalRequirements";
import useToast from "../../utils/useToast";
import {
  getUser,
  getUserPortrait,
  verifyIdApi,
  verifyTokenApi,
} from "../../services/api";
import { SUCCESS, REQUIRES_INPUT, getStatusFromUser } from "../../utils";
import { getUserStatus } from "@privateid/cryptonets-web-sdk-alpha";
import Feedback from "../../components/Feedback";
import ValuesYourPrivacy from "../../components/ValuesYourPrivacy";
import PreDocument from "../../components/SignupComponents/PreDocument";
import {
  ACCOUNT_CREATED,
  ACCOUNT_CREATION_FAILED,
  MAX_VERIFY_COUNTS,
} from "../../constants";
import InsuranceCard from "../../components/SignupComponents/InsuranceCard";
import PersonalDetails from "../../components/SignupComponents/PersonalDetails";
import RegisterInputsSsn from "../../components/RegisterSsn";
import AddressDetail from "../../components/SignupComponents/AddressDetails";
import Failure from "../../components/Failure";
import { CvsRequestContext } from "../../context/RequestContext";
import { UserStatusPayload } from "../../interface";
import Animation from "../../components/SignupComponents/Animation";
import DLAnimation from "../../components/SignupComponents/DLAnimation";
import bannerBottom from "../../assets/bannerBottom.png";
import bannerImage from "../../assets/banner.png";

interface RegisterPageProps {
  theme: string;
  skin: string;
}

const RegisterPage = ({ theme, skin }: RegisterPageProps) => {
  const { showToast } = useToast();
  const context = useContext(UserContext);
  const requestContext = useContext(CvsRequestContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tokenParams = searchParams.get("token") as string;
  const [step, setStep] = useState("");
  const [prevStep, setPrevStep] = useState(STEPS.PRE_ENROLL);
  const [loading, setLoading] = useState(false);
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));

  const postOidc: () => void = () => {
    // FOR OIDC CONNECTER NEEDS TO POST LIKE A FORM
    if (requestContext.requestFromOIDC) {
      const form = document.createElement("form");
      form.method = "POST";
      form.action = `https://oidc.privateid.com/interaction/${requestContext.interactionUID}/register`;
      const params: any = {
        uuid: context.uuid,
        guid: context.guid,
        token: context.tokenParams,
      };
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const hiddenField = document.createElement("input");
          hiddenField.type = "hidden";
          hiddenField.name = key;
          hiddenField.value = params[key];
          form.appendChild(hiddenField);
        }
      }
      document.body.appendChild(form);
      form.submit();
    }
  };

  const failureSessionRedirect = (session: { failureUrl: string | URL }) => {
    showToast("Your ID verification was not completed.", "error");
    if (session.failureUrl) {
      setStep(STEPS.FAILURE);
    }
  };

  const convertLinkToImageData = async (link: string, setState: any) => {
    const newImg = new Image();
    newImg.src = "data:image/png;base64," + link;
    newImg.onload = async () => {
      const imgSize = {
        w: newImg.width,
        h: newImg.height,
      };
      const canvas = document.createElement("canvas");
      canvas.setAttribute("height", `${imgSize.h}`);
      canvas.setAttribute("width", `${imgSize.w}`);
      const ctx = canvas.getContext("2d");
      // @ts-ignore
      ctx.drawImage(newImg, 0, 0);
      // @ts-ignore
      const enrollImage = ctx.getImageData(0, 0, imgSize.w, imgSize.h);
      setState(enrollImage);
      context.setDlAction("frontscan");
      setStep(STEPS.DRIVERLICENSE);
    };
  };

  const verifyTokenAPI = async (token: any) => {
    await verifyTokenApi(token).then(handleTokenVerification);
    context.setTokenParams(token);
  };

  const handleTokenVerification = async (res: any) => {
    context.setVerificationSession(res);

    if (res?.customerInformation?.customerId) {
      await handleCustomerVerification(res.customerInformation.customerId);
    } else {
      setStep(STEPS.PRE_ENROLL);
    }
  };

  const handleCustomerVerification = async (customerId: any) => {
    context.setId(customerId);
    const userDetails: any = await getUser(customerId);
    const userStatus: any = await getUserStatus({ id: customerId });
    const { userApproved, requestScanID, requestResAddress, ...rest } =
      userStatus || {};
    if (!userDetails?.email && !userDetails?.phone) {
      setStep(STEPS.PRE_ENROLL);
    } else if (
      !userDetails?.uuid ||
      !userDetails?.guid ||
      !userDetails.portrait
    ) {
      setStep(STEPS.ENROLL);
    } else if (
      !userDetails.govId?.portraitConfScore &&
      userDetails.govId?.portraitConfScore !== 0 &&
      !requestScanID
    ) {
      const userPortrait: any = await getUserPortrait(customerId);
      if (userPortrait?.imagedata) {
        await convertLinkToImageData(
          userPortrait.imagedata,
          context.setEnrollImageData
        );
        setStep(STEPS.DRIVERLICENSE);
      } else {
        setStep(STEPS.FAILURE);
      }
    } else if (!userDetails.govId?.firstName) {
      context.setDlAction("backscan");
      setStep(STEPS.DRIVERLICENSE);
    } else {
      context.setUserStatus({
        userApproved,
        requestScanID,
        requestResAddress: !requestScanID && requestResAddress,
        ...rest,
      });
      handleUserStatus(userApproved, rest);
    }
  };

  const handleUserStatus = (userApproved: any, rest: any) => {
    const status = getStatusFromUser({ userApproved, ...rest });
    const session = context.verificationSession;

    if (status === SUCCESS) {
      showToast("You successfully completed your ID verification.", "success");
      if (session.successUrl) {
        setStep(STEPS.SUCCESS);
      }
    } else if (status === REQUIRES_INPUT) {
      if (context.verifyAttempts >= MAX_VERIFY_COUNTS) {
        setStep(STEPS.FAILURE);
      }
      context.setVerifyAttempts(context.verifyAttempts + 1);
      showToast("We need more information to verify your identity.", "error");
      setStep(STEPS.ADDITIONAL_REQUIREMENTS);
    } else {
      failureSessionRedirect(session);
    }
  };
  useEffect(() => {
    if (!tokenParams) return;
    verifyTokenAPI(tokenParams);
  }, [tokenParams]);

  const onVerifyId = async () => {
    if (loading) return false;
    setLoading(true);
    setStep(STEPS.ANIMATION);
    const payload = {
      token: context.id,
    };
    await verifyIdApi({ id: tokenParams, payload });
    const {
      userApproved,
      requestScanID,
      requestResAddress,
      ...rest
    }: UserStatusPayload =
      (await getUserStatus({
        id: context.id,
      })) || {};

    context.setUserStatus({
      userApproved,
      requestScanID,
      requestResAddress: !requestScanID && requestResAddress,
      ...rest,
    });
    const status = getStatusFromUser({ userApproved, ...rest });
    const session = context.verificationSession;

    if (status === SUCCESS) {
      showToast("You successfully completed your ID verification.", "success");
      if (session.successUrl) {
        setStep(STEPS.SUCCESS);
      }
    } else if (status === REQUIRES_INPUT) {
      if (context.verifyAttempts >= MAX_VERIFY_COUNTS) {
        setStep(STEPS.FAILURE);
      }
      context.setVerifyAttempts(context.verifyAttempts + 1);
      showToast("We need more information to verify your identity.", "error");
      setStep(STEPS.ADDITIONAL_REQUIREMENTS);
    } else {
      failureSessionRedirect(session);
    }
    setLoading(false);
  };

  const _renderChildren = () => {
    switch (step) {
      case STEPS.REGISTER_CONSENT:
        return (
          <DatabaseConsent
            theme={theme}
            skin={skin}
            setStep={setStep}
            setPrevStep={setPrevStep}
          />
        );

      case STEPS.PRIVACY_CONSENT:
        return (
          <ValuesYourPrivacy
            setPrevStep={setPrevStep}
            skin={skin}
            setStep={setStep}
            theme={theme}
          />
        );
      case STEPS.REGISTER_FORM:
        return (
          <RegisterInputs
            matchesSM={matchesSM}
            setStep={setStep}
            skin={skin}
            setPrevStep={setPrevStep}
          />
        );
      case STEPS.REGISTER_FORM_SSN:
        return (
          <RegisterInputsSsn
            matchesSM={matchesSM}
            setStep={setStep}
            skin={skin}
            setPrevStep={setPrevStep}
          />
        );
      case STEPS.CONSENT_FAIL:
        return (
          <CannotVerify
            theme={theme}
            skin={skin}
            setStep={setStep}
            prevStep={prevStep}
          />
        );
      case STEPS.PRE_ENROLL:
        return (
          <VerifyAgeWithScan
            theme={theme}
            skin={skin}
            setPrevStep={setPrevStep}
            setStep={setStep}
          />
        );
      case STEPS.ENROLL:
        return <Enroll setStep={setStep} skin={skin} />;

      case STEPS.CAMERA_PERMISSION_FAIL:
        return (
          <CameraPermissionFail
            matchesSM={matchesSM}
            setStep={setStep}
            skin={skin}
            setPrevStep={setPrevStep}
          />
        );

      case STEPS.DRIVERLICENSE:
        return <DLScan setStep={setStep} onSuccess={onVerifyId} skin={skin} />;
      case STEPS.SWITCH_DEVICE:
        return <FullWidthTabs setStep={setStep} skin={skin} />;
      case STEPS.SUCCESS:
        return (
          <Success
            matchesSM={matchesSM}
            skin={skin}
            postToOidc={postOidc}
            setStep={setStep}
            message={ACCOUNT_CREATED}
          />
        );
      case STEPS.FAILURE:
        return (
          <Failure
            matchesSM={matchesSM}
            skin={skin}
            postToOidc={postOidc}
            isLogin={false}
            setStep={setStep}
            message={ACCOUNT_CREATION_FAILED}
          />
        );
      case STEPS.ADDITIONAL_REQUIREMENTS:
        return (
          <AdditionalRequirements
            matchesSM={matchesSM}
            setStep={setStep}
            skin={skin}
            setPrevStep={setPrevStep}
            handleRequirementsComplete={onVerifyId}
            loading={loading}
          />
        );
      case STEPS.FEEDBACK:
        return (
          <Feedback
            matchesSM={matchesSM}
            setStep={() => setStep(STEPS.PRE_ENROLL)}
            skin={skin}
          />
        );
      case STEPS.PRE_DOCUMENT:
        return <PreDocument theme={theme} skin={skin} setStep={setStep} />;
      case STEPS.INSURANCE_CARD:
        return <InsuranceCard theme={theme} skin={skin} setStep={setStep} />;
      case STEPS.PERSONAL_DETAILS:
        return (
          <PersonalDetails
            matchesSM={matchesSM}
            setStep={setStep}
            skin={skin}
            setPrevStep={setPrevStep}
          />
        );
      case STEPS.ADDRESS_DETAIL:
        return (
          <AddressDetail
            matchesSM={matchesSM}
            setStep={setStep}
            skin={skin}
            setPrevStep={setPrevStep}
            onSuccess={onVerifyId}
          />
        );
      case STEPS.ANIMATION:
        return <Animation />;

      // case STEPS.DL_ANIMATION:
      //   return (
      //     <DLAnimation matchesSM={matchesSM} setStep={setStep} />
      //   )
      default:
        return <></>;
    }
  };
  const themeName = skin || "primary";
  const onFeedback = () => {
    setStep(STEPS.FEEDBACK);
    setPrevStep(step);
  };
  return (
    <>
      {<Header theme={themeName} />}
      <Container maxWidth="lg" disableGutters={matchesSM}>
        <div className="homePageWrapper homeComponentWrap homePageWrapperMobile">
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={matchesSM ? 12 : 6}>
              <HomeModal
                handleClose={() => {
                  navigate("/");
                }}
                open={true}
                onFeedback={onFeedback}
                showFeedback={step !== STEPS.FEEDBACK}
                step={step}
              >
                {_renderChildren()}
              </HomeModal>
            </Grid>
            {!matchesSM && (
              <Grid item xs={matchesSM ? 12 : 6}>
                <div className="homeSidebarImg">
                  <img src={bannerImage} alt="women" />
                </div>
              </Grid>
            )}
          </Grid>
        </div>
        <img src={bannerBottom} alt="BannerBottom" className="bannerBottom" />
      </Container>
    </>
  );
};

export default RegisterPage;
