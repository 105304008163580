// @ts-nocheck
/* eslint-disable no-unused-vars */
import { useState } from "react";
import { enroll1FA } from "@privateid/cryptonets-web-sdk-alpha";
import Rerun from "../utils/reRuncheck";
import { getStatusMessage } from "@privateid/cryptonets-web-sdk-alpha/dist/utils";

const useEnrollOneFaWithLiveness = (
  element,
  onSuccess,
  retryTimes = 4,
  deviceId = null
) => {
  const [enrollStatus, setEnrollStatus] = useState(null);
  const [progress, setProgress] = useState(0);
  const [enrollPortrait, setEnrollPortrait] = useState<ImageData>();
  const [enrollData, setEnrollData] = useState<{uuid: string, guid: string} | undefined>();

  let showError = false;

  const enrollUserOneFa = async () => {
    RerunAction.doInterval();
    setEnrollStatus(null);
    setProgress(0);
    // eslint-disable-next-line no-unused-vars
    try {
      const { imageData, height, width } = await enroll1FA(
        callback,
        {
          input_image_format: "rgba",
        },
        false
      );
      if (imageData && width && height) {
        setEnrollPortrait(new ImageData(imageData, width, height));
      }
    } catch (e) {
      enrollUserOneFa();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const RerunAction = new Rerun(enrollUserOneFa);

  const callback = async (result) => {
    RerunAction.RerunAction = false;
    switch (result.status) {
      case "VALID_FACE":
        setEnrollStatus(null);
        setProgress(result.progress);
        if (result.progress === 100) {
          RerunAction.clearCheck();
        }
        break;
      case "INVALID_FACE":
        if (!showError) {
          showError = true;
          if(result?.livenessCheck === -1) {
            setEnrollStatus("Move closer to camera")
          } else if(result?.livenessCheck === 1){
            setEnrollStatus("Please move back")
          }
          else{
            setEnrollStatus(result.result === -1 ? "Please position your face in the center of the circle" : getStatusMessage(result.result) || "");
          }
          setTimeout(() => {
            showError = false;
          }, 2000);
        }
        break;
      case "ENROLLING":
        setEnrollStatus("ENROLLING");
        break;
      case "WASM_RESPONSE":
        if (
          result.returnValue?.error === -1 ||
          result.returnValue?.error === -100 ||
          result.returnValue?.status === -1 ||
          result.returnValue?.status === -100
        ) {
          setEnrollStatus("ENROLL FAILED, PLEASE TRY AGAIN");
          enrollUserOneFa();
          return;
        }
        if (result.returnValue?.status === 0) {
          setEnrollStatus("ENROLL SUCCESS");
          setEnrollData(result.returnValue.PI)
          onSuccess(result.returnValue.PI);
        }
        break;
      default:
    }
  };

  return {
    enrollStatus,
    enrollUserOneFa,
    progress,
    enrollPortrait,
    enrollData
  };
};

export default useEnrollOneFaWithLiveness;
