import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { styles, useStyles } from "../../../styles/styles";
import Heart from "../../../assets/heart.gif";

const Animation = () => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  return (
    <Box
      mt={3}
      sx={{
        height: "100%",
        backgroundColor: "#ffe97d",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
      }}
    >
      <img
        src={Heart}
        alt=""
        width={matchesSM ? "100%" : "500px"}
        style={styles.AnimationImage}
      />
      <Box
        sx={{
          // backgroundColor: "grey",
        }}
      >
        <Typography
          component="p"
          textAlign={"center"}
          fontSize={18}
          fontWeight={700}
          lineHeight={1.5}
          pt={2}
          pb={2}
          className={classes.cardInnerHeading}
          color={"#262626"}
          px={10}
        >
          Preparing your account …
        </Typography>
      </Box>
    </Box>
  );
};

export default Animation;
