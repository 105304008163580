import React, { createContext, useMemo, useState } from "react";

export const LoginContext = createContext({
    predictedGUID: "",
    setPredictedGUID: (predictedGUID: string | undefined) => { },
    barcodeHash: "",
    setBarcodeHash: (barcodeHash: string) => { },
    errorMessage: "",
    setErrorMessage: (errorMessage:string) => {},
});


const LoginContextProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {

    const [predictedGUID, setPredictedGUID] = useState<any>(undefined);
    const [barcodeHash, setBarcodeHash] = useState<any>(undefined);
    const [errorMessage, setErrorMessage] = useState<any>(undefined);
    const memoizedValue = useMemo(
      () => ({
        predictedGUID,
        setPredictedGUID,
        barcodeHash,
        setBarcodeHash,
        errorMessage,
        setErrorMessage,
      }),
      [
        predictedGUID,
        setPredictedGUID,
        barcodeHash,
        setBarcodeHash,
        errorMessage,
        setErrorMessage,
      ]
    );
  return (
    <LoginContext.Provider value={memoizedValue}>
      {children}
    </LoginContext.Provider>
  );
};

export default LoginContextProvider;
