import { useState, useContext, useEffect } from "react";
import { useTheme, useMediaQuery } from "@mui/material";

import HomeModal from "../../components/Modal/homeModal";
import Header from "../../components/Header";
import STEPS from "./steps";
import { useNavigate } from "react-router";
import useToast from "../../utils/useToast";
import Login from "../../components/AuthenticateComponents/Login";
import LoginSuccess from "../../components/AuthenticateComponents/LoginSuccess";
import PreLogin from "../../components/AuthenticateComponents/PreLogin";
import PreDocumentScan from "../../components/AuthenticateComponents/PreDocumentScan";
import BackDocumentScanModular from "../../components/AuthenticateComponents/BackDocumentScan";
import LoginFailure from "../../components/AuthenticateComponents/LoginFailure";
import { CvsRequestContext } from "../../context/RequestContext";
import { LoginContext } from "../../context/LoginContext";
import { antispoofCheck } from "@privateid/cryptonets-web-sdk-alpha/dist/utils";
import { closeCamera } from "@privateid/cryptonets-web-sdk-alpha";
import SwitchDeviceWithoutToken from "../../components/AuthenticateComponents/SwitchDeviceWithoutToken";
import { getUser } from "../../services/api";
import { ERROR, SUCCESS, getStatusFromUser } from "../../utils";


export enum FailureTypeEnum {
  USER_NOT_ENROLLED = "UserNotEnrolled",
  FACE_NOT_FOUND = "FaceNotFound",
}

interface FaceLoginWithDocumentProps {
  theme: string;
  skin: string;
}

export const _renderChildren = (props: any) => {
  const {
    step,
    setStep,
    skin,
    theme,
    onLoginSuccess,
    onLoginFailure,
    onBackDocumentScanSuccess,
    matchesSM,
    postToOidc,
    onSwitchDevice,
    redirectUrl,
  } = props;
  switch (step) {
    case STEPS.START:
      return <PreLogin setStep={setStep} skin={skin} theme={theme} />;
    case STEPS.LOGIN:
      return (
        <Login
          skin={skin}
          setStep={setStep}
          onSuccess={onLoginSuccess}
          onFailure={onLoginFailure}
          onSwitchDevice={onSwitchDevice}
        />
      );
    case STEPS.PRE_DOCUMENT_SCAN:
      return <PreDocumentScan skin={skin} setStep={setStep} />;
    case STEPS.BACK_DOCUMENT_SCAN:
      return <BackDocumentScanModular onSuccess={onBackDocumentScanSuccess} onSwitchDevice={onSwitchDevice} />;
    case STEPS.SUCCESS:
      return (
        <LoginSuccess
          matchesSM={matchesSM}
          skin={skin}
          postToOidc={postToOidc}
        />
      );
    case STEPS.FAILURE:
      return (
        <LoginFailure
          matchesSM={matchesSM}
          skin={skin}
          postToOidc={postToOidc}
        />
      );

    case STEPS.SWITCH_DEVICE:
      return (
        <SwitchDeviceWithoutToken redirectUrl={redirectUrl} />
      );
    default:
      return <></>;
  }
};

const FaceLoginWithDocument = ({ theme, skin }: FaceLoginWithDocumentProps) => {
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [step, setStep] = useState(STEPS.LOGIN);
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const requestContext = useContext(CvsRequestContext);
  const loginContext = useContext(LoginContext);


  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const guidParam = urlParams.get("guid");
    if(guidParam){
      loginContext.setPredictedGUID(guidParam);
      setStep(STEPS.PRE_DOCUMENT_SCAN);
    }
  },[])
  // do Login
  const [loginResult, setLoginResult] = useState<any>("");
  const onLoginSuccess = async (loginData: any, inputImage: any) => {
    setLoginResult(loginData);
    loginContext.setPredictedGUID(loginData.PI.guid);

    const antiSpoofResult = await antispoofCheck(
      inputImage.data,
      inputImage.width,
      inputImage.height
    );

    if (antiSpoofResult.livenessCheck === 1) {
      loginContext.setErrorMessage("Authentication Failed - Spoofing detected.")
      setStep(STEPS.FAILURE);
    }
    else {
      const payload = {
        guid: loginData?.PI?.guid,
      };
      const data: any = await getUser(payload);
      if (data?.data?.level === ERROR) {
        loginContext.setErrorMessage("Authentication Failed - User not verified.")
        setStep(STEPS.FAILURE);
      }
      else {
        const userStatus = getStatusFromUser(data);
        if (userStatus === SUCCESS) {
          setTimeout(() => {
            setStep(STEPS.PRE_DOCUMENT_SCAN);
          }, 3000);
        } else {
          loginContext.setErrorMessage("Authentication Failed - User not verified")
          showToast("Authentication Failed - User not verified", "error", 4);
          setTimeout(() => {
            setStep(STEPS.FAILURE);
          }, 300)
        }
      }
    }
  };

  const postToOidc = () => {
    if (requestContext.requestFromOIDC) {
      const form = document.createElement("form");
      form.method = "POST";
      form.action = `https://oidc.privateid.com/interaction/${requestContext.interactionUID}/login`;
      const params: any = loginResult
        ? {
          uuid: loginResult.PI.uuid,
          guid: loginResult.PI.guid,
        }
        : {};
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const hiddenField = document.createElement("input");
          hiddenField.type = "hidden";
          hiddenField.name = key;
          hiddenField.value = params[key];
          form.appendChild(hiddenField);
        }
      }
      document.body.appendChild(form);
      form.submit();
    }
  };

  // do Back document Scan
  const onBackDocumentScanSuccess = async (documentData: any) => {
    setStep(STEPS.SUCCESS);
  };

  const onLoginFailure = async (type: FailureTypeEnum) => {
    await closeCamera(undefined);
    const ErrorMessage =
      type === FailureTypeEnum.USER_NOT_ENROLLED
        ? "Authentication Failed - User Not Enrolled"
        : "Authentication Failed - No Face Detected";
    loginContext.setErrorMessage(ErrorMessage);
    showToast(ErrorMessage, "error", 10000);
    setTimeout(
      () => {
        setStep(STEPS.FAILURE);
      }
      , 500
    )

  };

  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const onSwitchDevice = async () => {
    setRedirectUrl(`${window.location.pathname}?guid=${loginContext.predictedGUID}`);
    await closeCamera(undefined);
    setTimeout(()=>{
      setStep(STEPS.SWITCH_DEVICE);
    },300)
  }

  const themeName = skin || "primary";
  return (
    <>
      {<Header theme={themeName} />}
      <div className="homePageWrapper">
        <HomeModal
          handleClose={() => {
            navigate("/");
          }}
          step={step}
          open={true}
          onFeedback={() => { }}
          showFeedback={false}
        >
          {_renderChildren({
            step,
            setStep,
            skin,
            theme,
            onLoginSuccess,
            onLoginFailure,
            onBackDocumentScanSuccess,
            matchesSM,
            postToOidc,
            onSwitchDevice,
            redirectUrl,
          })}
        </HomeModal>
      </div>
    </>
  );
};

export default FaceLoginWithDocument;
