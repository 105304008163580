import { makeStyles } from "@mui/styles";
import { localThemes, theme as Theme } from "../../theme";

const mainTheme = Theme;
const palette: { [key: string]: any } = mainTheme.palette;
const skin = localThemes?.includes(window?.location?.search?.split("skin=")[1])
  ? window?.location?.search?.split("skin=")[1]
  : "primary";

export const useStyles = makeStyles((theme: any) => ({
  homeHeading: {
    textAlign: "left",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "28px !important",
      lineHeight: "40px  !important",
      textAlign: "center",
    },
  },
  homeSubHeading: {
    textAlign: "left",
    [theme.breakpoints.between("xs", "sm")]: {
      // fontSize: "16px !important",
      lineHeight: "40px",
      textAlign: "center",
      padding: "10px 8px",
      paddingBottom: "0px",
      marginTop: "0px !important",
      marginBottom: "2rem !important",
    },
    "@media (orientation: landscape)": {
      [theme.breakpoints.down(1024)]: {
        marginBottom: "10px !important",
        paddingTop: "0px !important",
        marginTop: "-10px !important",
      },
    },
  },
  buttonsGrid: {
    [theme.breakpoints.between("xs", "sm")]: {
      paddingTop: "20px !important",
      zIndex: 99,
      position: "relative",
    },
    "@media (orientation: landscape)": {
      [theme.breakpoints.down(1024)]: {
        paddingTop: "0px !important",
      },
    },
  },
  buttonsWrap: {
    justifyContent: "flex-start",
    [theme.breakpoints.between("xs", "sm")]: {
      justifyContent: "center",
      alignItems: "center !important",
    },
  },
  buttonsWrapButton: {
    fontWeight: "500 !important",
    marginBottom: "12px !important",
    borderRadius: "7px !important",
    zIndex: 99,
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: "0 !important",
      width: "100%",
      marginBottom: "9px !important",
      textAlign: "center",
      zIndex: 99,
    },
    [theme.breakpoints.down(380)]: {
      minWidth: "300px !important",
    },
    "& p": {
      [theme.breakpoints.between("xs", "sm")]: {
        position: "absolute",
        right: 8,
        bottom: 0,
        margin: 0,
        fontSize: 12,
      },
    },
    "&:hover": {
      // backgroundColor: "transparent !important",
      // color: `${palette[skin]?.cvs} !important`,
      textDecoration: "underline !important",
    },
  },
  flowdropDown: {
    width: "300px",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
    },
    "& fieldset": {
      width: "281px",
      border: `1px solid ${palette[skin]?.listText} !important`,
      [theme.breakpoints.between("xs", "sm")]: {
        width: "96%",
      },
    },
    "& .MuiInputBase-input": {
      color: palette[skin]?.listText,
    },
    "& svg": {
      color: palette[skin]?.listText,
    },
  },
  menuPaper: {
    maxHeight: "250px !important",
    [theme.breakpoints.between("xs", "sm")]: {
      bottom: "12rem !important",
      top: "unset !important",
    },
  },
  homeLoader: {
    color: `${palette[skin]?.cvs} !important`,
    height: "35px  !important",
    width: "35px  !important",
    margin: "0 auto",
  },
  buttonsBox: {
    display: "flex",
    flexDirection: "column",
    width: "200px",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
    },
  },
  redTubeButton: {
    backgroundColor: "#b70000 !important",
    borderColor: "#b70000 !important",
    color: `${palette[skin]?.text} !important`,
    boxShadow: "unset !important",
  },
  mainWrap: {
    [theme.breakpoints.between("xs", "sm")]: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
  },
  innerWrap: {
    [theme.breakpoints.between("xs", "sm")]: {
      flex: 1,
    },
  },
  bottomGrid: {
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none"
    },
    "@media (orientation: landscape)": {
      [theme.breakpoints.down(1024)]: {
        display: "none"
      },
    },
  },
}));
