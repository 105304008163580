import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";

interface MonthPickerprops {
  theme?: string;
  value?: Dayjs | null;
  setEnrollData?: (e: any) => void;
  day?: boolean;
  helperText?: string;
}

const MonthPicker = (props: MonthPickerprops) => {
  const { value, setEnrollData, day, helperText } = props;
  const maxDate = dayjs();
  const minDate = dayjs().subtract(120, "years");

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value ?? null}
        label="Date of Birth"
        views={day ? ["year", "month", "day"] : ["year", "month"]}
        maxDate={maxDate}
        minDate={minDate}
        onChange={(newValue: any) => {
          setEnrollData?.(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              "& .MuiInputBase-root": {
                backgroundColor: '#fff'
              },
              "& .MuiFormHelperText-contained": {
                color: "red",
              },
              mt: 0,
              mb: 2,
            }}
            helperText={helperText}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default MonthPicker;
