import React, { useContext } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useStyles } from "./styles";
import SelfieEmoji from "../../../assets/selfie.png";
import DlFront from "../../../assets/front-dl.png";
import DlBack from "../../../assets/back-dl.png";
import STEPS from "../../../pages/register/steps";
import { UserContext } from "../../../context/UserContext";
import { DlActionEnum } from "../../DLScanning/DLFaceCompare";

interface HomeModalProps {
  open: boolean;
  handleClose?: () => void;
  theme?: string;
  children?: React.ReactNode;
  skin?: string;
  onBack?: () => void;
  onFeedback?: () => void;
  showFeedback?: boolean;
  displayFeedback?: boolean;
  step?: string;
  removePedding?: boolean;
}

const HomeModal = (props: HomeModalProps) => {
  const context = useContext(UserContext);
  const { dlAction } = context;
  const { open, step, removePedding } = props;
  const children: any = props?.children;
  const classes = useStyles();
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const isHints = step
    ? [
        // STEPS.ENROLL,
        // STEPS.DRIVERLICENSE,
        // STEPS.SIGN_IN,
        // STEPS.BACK_DOCUMENT_SCAN,
        STEPS.LOGIN,
      ].includes(step)
    : false;
  const isSelfieImage = step
    ? [
        STEPS.REGISTER_FORM,
        // STEPS.ENROLL,
        STEPS.REGISTER_CONSENT,
        STEPS.PRIVACY_CONSENT,
        // STEPS.DRIVERLICENSE,
        STEPS.PRE_ENROLL,
        // STEPS.SIGN_IN,
        STEPS.LOGIN,
        // STEPS.BACK_DOCUMENT_SCAN,
        STEPS.PRE_DOCUMENT_SCAN,
      ].includes(step)
    : false;
  return open ? (
    <Box
      // mt={matchesSM ? 0 : props?.step === STEPS.DRIVERLICENSE ? 14 : 17}
      className={classes.homeModalCover}
      sx={{ position: "relative", flexDirection: "column" }}
    >
      <Card
        sx={{
          position: "relative",
          paddingLeft: matchesSM ? 0 : 3,
          maxWidth: isHints ? 900 : isSelfieImage ? "100%" : "100%",
        }}
        className={classes.cardInner}
      >
        <CardContent className={classes.cardChild}>
          {!matchesSM && isSelfieImage && (
            <img
              src={
                step === STEPS.DRIVERLICENSE
                  ? dlAction === DlActionEnum.backscan
                    ? DlBack
                    : DlFront
                  : SelfieEmoji
              }
              alt="scan"
              width={matchesSM ? "150px" : ""}
              height={matchesSM ? "150px" : "150px"}
              className={classes.setfieImage}
            />
          )}
          <div
            style={{
              paddingLeft: removePedding ? "0" : matchesSM ? 0 : 20,
              flex: 1,
            }}
          >
            {children}
          </div>
          {isHints && (
            <Box
              className={classes.hintsWrap}
              sx={{ marginLeft: step === STEPS.ENROLL ? "20px" : "" }}
            >
              <Typography
                component="p"
                textAlign={matchesSM ? "center" : "left"}
                fontSize={24}
                fontWeight={500}
                mt={0}
                color={"#aaa"}
              >
                Helpful Hints
              </Typography>

              <Typography
                component="p"
                textAlign={matchesSM ? "center" : "left"}
                fontSize={16}
                fontWeight={500}
                mt={4}
                color={"#aaa"}
              >
                Turn on the lights
              </Typography>
              {step === STEPS.DRIVERLICENSE ? (
                <>
                  <Typography
                    component="p"
                    textAlign={matchesSM ? "center" : "left"}
                    fontSize={16}
                    fontWeight={500}
                    mt={4}
                    color={"#aaa"}
                  >
                    Make sure there is no glare
                  </Typography>
                  <Typography
                    component="p"
                    textAlign={matchesSM ? "center" : "left"}
                    fontSize={16}
                    fontWeight={500}
                    mt={4}
                    color={"#aaa"}
                  >
                    Hold the card steady
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    component="p"
                    textAlign={matchesSM ? "center" : "left"}
                    fontSize={16}
                    fontWeight={500}
                    mt={4}
                    color={"#aaa"}
                  >
                    Remove glasses, hat and face mask
                  </Typography>
                  <Typography
                    component="p"
                    textAlign={matchesSM ? "center" : "left"}
                    fontSize={16}
                    fontWeight={500}
                    mt={4}
                    color={"#aaa"}
                  >
                    Move your head to the middle of the window
                  </Typography>
                </>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  ) : null;
};

export default HomeModal;
