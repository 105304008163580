import { Box } from "@mui/material";
import { useStyles } from "./styles";

const TooltipContainer = ({ children, content, position }: any) => {
  const classes = useStyles();
  return (
    <Box className={classes.tooltipWrap}>
      <Box
        className={`${position === "left" && classes.tooltipLeft} ${
          classes.tooltip
        }`}
      >
        {content}
      </Box>
      {children}
    </Box>
  );
};

export default TooltipContainer;
