import { useTheme, useMediaQuery } from "@mui/material";
import { useStyles } from "../Login/styles";
import React, { useState } from "react";
import STEPS from "../../../pages/login/steps";
import useFaceLogin from "../../../hooks/useFaceLoginWithLiveness";
import useDelete from "../../../hooks/useDelete";
import { LoginWrap } from "../Login";

const ForgetMe = ({
  setStep,
}: {
  onReadyCallback?: (e: boolean) => void;
  onSwitchCamera?: () => void;
  message?: string;
  setStep: React.Dispatch<React.SetStateAction<string>>;
  skin: string;
}) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [showSuccess, setShowSuccess] = useState(false);
  const [hasNoCamera, setHasNoCamera] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const onSuccess = (result: any) => {
    setShowSuccess(true);
    onDeleteUser(result?.PI?.uuid);
  };

  const {
    faceLoginWithLiveness: doFaceLogin,
    faceLoginWithLivenessMessage: faceLoginMessage,
  } = useFaceLogin(onSuccess);

  const onDeleteSuccess = async (isSuccess: any) => {
    if (isSuccess === "success") {
      setStep(STEPS.SUCCESS);
    } else {
      setDeleteMessage(isSuccess);
    }
  };

  const { onDeleteUser } = useDelete(onDeleteSuccess);

  const onCameraFail = async () => {
    setHasNoCamera(true);
  };

  return (
    <>
      <LoginWrap
        matchesSM={matchesSM}
        showSuccess={showSuccess}
        hasNoCamera={hasNoCamera}
        doFaceLogin={doFaceLogin}
        onCameraFail={onCameraFail}
        deleteMessage={deleteMessage}
        faceLoginMessage={faceLoginMessage}
        classes={classes}
        setStep={setStep}
      />
    </>
  );
};
export default ForgetMe;
