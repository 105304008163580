import React from "react";
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Grid,
  Divider,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router";
import logoBlack from "../../assets/privateidLogo.png";
import mainLogo from "../../assets/cvsHealthLogo.png";
import { styles, useStyles } from "./styles";
import useDelete from "../../hooks/useDelete";
import { createVerificationSession, deleteUserApi } from "../../services/api";
import useToast from "../../utils/useToast";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import config from "../../config";
import { createSearchParams } from "react-router-dom";
import useCameraPermissions from "../../hooks/useCameraPermissions";
import { useSkinContext } from "../../context/SkinContext";
import TooltipContainer from "../TooltipContainer";
import { ELEMENT_ID } from "../../constants";
import { closeCamera } from "@privateid/cryptonets-web-sdk-alpha";
import privateIdLogo from "../../assets/privateidLogo.png"

interface HeaderProps {
  theme?: string;
  onCloseCamera?: () => void;
}

const Header = (props: HeaderProps) => {
  const routes = [
    "/authenticate",
    "/",
    "/register",
    "/signin",
    "/terms",
    "/privacy",
    "/login_with_barcode",
    "/login",
    "/continuous_authentication",
  ];

  // const { isCameraGranted } = useCameraPermissions();
  const { theme, onCloseCamera } = props;
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const { skin } = useSkinContext();
  const user = JSON.parse(localStorage.getItem("user") ?? "{}");
  const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
  const { showToast } = useToast();
  const navigate = useNavigate();
  const classes = useStyles();

  const renderHeaderImage = () => {
    const isAtHomePage = routes.includes(window.location.pathname);
    if (isAtHomePage) {
      return (
        <img
          // src={mainLogo}
          src={privateIdLogo}
          alt=""
          width={skin === "c1" ? 120 : 160}
          onClick={handleHeaderImageClick}
          style={{ cursor: "pointer" }}
          className="headerLogo"
        />
      );
    }
    return null;
  };

  const handleHeaderImageClick = () => {
    if (onCloseCamera) {
      onCloseCamera();
    } else {
      navigate("/");
    }
  };

  const onSignInClick = async () => {
    navigate("/signin")
  };

  const createVerification = async (step?: string) => {
    const payload = config.clientConfig;
    const result: any = await createVerificationSession(payload);
    if (result?.token) {
      navigate({
        pathname: "/register",
        search: createSearchParams({
          token: result?.token || "",
          step: step ?? "",
        }).toString(),
      });
    }
  };

  const handleLogout = () => {
    closeCamera(ELEMENT_ID);
    localStorage.removeItem("user");
    localStorage.removeItem("uuid");
    setTimeout(() => navigate("/"), 50);
  };

  const handleDeleteAccount = async () => {
    const payload = {
      guid: user?.guid,
    };
    await onDeleteUser(uuid || user?.uuid);
    await deleteUserApi(payload as any);
    showToast("Deleted Successfully", "success");
    localStorage.removeItem("user");
    localStorage.removeItem("uuid");
    navigate("/");
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { onDeleteUser } = useDelete(null);

  const renderAccountMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 99999991,
            },
            ul: { paddingBottom: 0, paddingTop: 0 },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <TooltipContainer
          position="left"
          content="Delete their account which will delete the users existing UUID and allow them to register again. (once a user deletes their account they will have to register again and a new UUID will be created)"
        >
          <MenuItem className="AgeItem" onClick={handleDeleteAccount}>
            Delete account
          </MenuItem>
        </TooltipContainer>
        <Divider color="#000" style={{ margin: 0 }} />
        <TooltipContainer
          position="left"
          content="Logout will log a user out and allow them to Authenticate (Sign In) again using their face with liveness detection with the option for face with liveness detection and barcode as a 3rd factor."
        >
          <MenuItem className="AgeItem" onClick={handleLogout}>
            Logout
          </MenuItem>
        </TooltipContainer>
      </Menu>
    );
  };

  const renderSignInButton = () => {
    if (
      ["/authenticate", "/", "/register", "/terms", "/privacy"].includes(
        window.location.pathname
      )
    ) {
      return (
        <Button
          color={theme as "inherit"}
          variant="contained"
          style={styles.signupButton}
          className={`${classes.headerButton} ${
            matchesSM ? classes.headerButtonMobile : ""
          }`}
          onClick={onSignInClick}
          sx={{
            textTransform: "unset",
            color: skin === "c1" ? "black" : undefined,
          }}
        >
          Sign in
        </Button>
      );
    }
  };

  let wrapper = routes?.includes(window?.location?.pathname)
    ? styles.homeBar
    : styles.appBar;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        style={{
          ...wrapper,
          borderBottom: "inherit",
          // backgroundColor: "inherit",
          zIndex: 9999999,
        }}
        className={classes.headerWrap}
      >
        <Toolbar>
          {renderHeaderImage()}
          {/* <Grid container alignItems="center" justifyContent="flex-end">
            {routes.includes(window?.location?.pathname) && (
              <img
                src={logoBlack}
                alt=""
                width={130}
                style={styles.homelogo}
                className="headerLogo centralLogo"
              />
            )}
          </Grid> */}
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            style={{ flex: 1 }}
          >
            {user?._id ? (
              <Button
                variant="contained"
                style={styles.signupButton}
                className={`${classes.signOutButton} ${
                  matchesSM ? classes.signOutButtonMobile : ""
                }`}
                onClick={handleLogout}
                sx={{
                  textTransform: "unset",
                }}
              >
                Sign out
              </Button>
            ) : (
              // <>
              //   <Tooltip title="Account settings">
              //     <IconButton
              //       onClick={handleClick}
              //       size="small"
              //       sx={{ ml: 2 }}
              //       aria-controls={open ? "account-menu" : undefined}
              //       aria-haspopup="true"
              //       aria-expanded={open ? "true" : undefined}
              //     >
              //       <Avatar sx={{ width: 32, height: 32 }} />
              //     </IconButton>
              //   </Tooltip>
              //   {renderAccountMenu()}
              // </>
              <>{renderSignInButton()}</>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
