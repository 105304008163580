import React, { useMemo } from "react";
import { localThemes } from "../theme";

const SkinContext = React.createContext({
  skin: "primary",
  setSkin: (skin: string) => {},
});

export default SkinContext;

export const SkinContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [skin, setSkin] = React.useState(
    localThemes?.includes(window?.location?.search?.split("skin=")[1])
      ? window?.location?.search?.split("skin=")[1]
      : "primary"
  );

  React.useEffect(() => {
    // we need to set the skin in the url
    const url = new URL(window.location.href);
    url.searchParams.set("skin", skin);
    window.history.pushState({}, "", url.toString());
  }, []);
  const memoValues = useMemo(() => ({ skin, setSkin }), []);
  return (
    <SkinContext.Provider value={memoValues}>{children}</SkinContext.Provider>
  );
};

export function useSkinContext() {
  return React.useContext(SkinContext);
}
