import * as React from "react";
import "react-phone-number-input/style.css";
import { Stack, Button, TextField, CircularProgress } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTheme, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import QRCode from "react-qr-code";
import Grid from "@mui/material/Grid";
import { Email as EmailIcon, ContentCopy } from "@mui/icons-material";
import PhoneIcon from "@mui/icons-material/Phone";
import { TabPanel } from ".";
import { useNavigate } from "react-router";
import useToast from "../../utils/useToast";
import { sendMessage } from "../../services/api";
import { styles, useStyles } from "../../styles/styles";
import CvsButton from "../Button";

interface StyledTabProps {
  label: string;
  className?: string;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: "rgba(0, 0, 0, 0.8)",
  "&.Mui-selected": {
    color: "#1194ff",
  },
}));

export default function OptionTab({
  value,
  handleChange,
  matchesSM,
  phone,
  setPhone,
  email,
  setEmail,
  stopApi,
  redirectUrl,
  skin,
}: any) {
  const [isLoading, setIsLoading] = React.useState(false);
  const classes = useStyles();
  const { showToast } = useToast();
  const theme = useTheme();
  const navigate = useNavigate();
  const pathAndQuery = (
    window.location.pathname + window.location.search
  ).slice(1);
  const sendPhone = async () => {
    const payload = {
      type: "phone",
      phone: `+1${phone}`,
      subject: "Continue your verification",
      message: `to continue your verification process, Please delete this message if you did not request this verification.`,
      endpoint: redirectUrl.slice(1) || pathAndQuery,
    };
    setIsLoading(true);
    await sendMessage(payload);
    setIsLoading(false);
    showToast("Message sent successfully", "success");
  };
  const sendEmail = async () => {
    const payload = {
      type: "email",
      email,
      subject: "Continue your verification",
      message: `to continue your verification process, Please delete this message if you did not request this verification.`,
      endpoint: redirectUrl.slice(1) || pathAndQuery,
    };
    setIsLoading(true);
    await sendMessage(payload);
    setIsLoading(false);
    showToast("Message sent successfully", "success");
  };
  return (
    <Grid container direction={"column"} my={1} mt={0}>
      <Grid
        item
        sx={{
          // bgcolor: "background.paper",
          width: "100%",
          height: matchesSM ? "470px" : "440px",
        }}
        className="tabWrap"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          variant="fullWidth"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#1194ff",
            },
          }}
        >
          <StyledTab label="QR Code" className="tab-inner" />
          <StyledTab label="Link" className="tab-inner" />
          <StyledTab label="SMS" className="tab-inner" />
          <StyledTab label="Email" className="tab-inner" />
        </Tabs>

        <TabPanel value={value} index={2} dir={theme.direction}>
          <Stack display={"flex"} direction={"column"} gap={2}>
            <Typography
              align={matchesSM ? "center" : "left"}
              component={"p"}
              variant="h6"
            >
              Receive a link via text message.
            </Typography>

            <Typography
              align={matchesSM ? "center" : "left"}
              component={"p"}
              variant="body1"
            >
              Enter your mobile number and we will send you a link to complete
              this verification on a different device.
            </Typography>
            <TextField
              id="outlined-basic"
              label="Phone"
              type="tel"
              placeholder="Phone"
              variant="outlined"
              fullWidth
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              InputProps={{
                endAdornment: <PhoneIcon />,
              }}
              inputProps={{ maxLength: 10 }}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "#fff",
                },
              }}
            />
            <Typography
              align={matchesSM ? "center" : "left"}
              component={"p"}
              variant="body2"
            >
              Standard messaging rates may apply.
            </Typography>
            <CvsButton
              title={isLoading ? <CircularProgress /> : "Send Link"}
              skin={skin}
              onClick={sendPhone}
            />
          </Stack>
        </TabPanel>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Stack display={"flex"} direction={"column"} gap={2}>
            <Typography
              align={matchesSM ? "center" : "left"}
              component={"p"}
              variant="h6"
              fontSize={"1rem"}
              fontWeight={500}
            >
              Scan QR code.
            </Typography>

            <Typography
              align={matchesSM ? "center" : "left"}
              component={"p"}
              variant="body1"
              fontSize={"14px"}
              fontWeight={500}
              width={matchesSM ? "unset" : 400}
            >
              Point your phone's camera at the image to follow the link.
            </Typography>

            <Box display="flex" justifyContent={"center"}>
              <QRCode
                size={150}
                value={
                  redirectUrl
                    ? window.location.origin + redirectUrl
                    : window.location.toString()
                }
              />
            </Box>
            {stopApi ? null : (
              <Typography
                align={"center"}
                component={"p"}
                variant="body1"
                fontSize={"14px"}
                fontWeight={500}
              >
                Page redirects after completion on other device
              </Typography>
            )}
            <CvsButton
              title={isLoading ? <CircularProgress /> : "Back to homepage"}
              skin={skin}
              onClick={() => navigate("/")}
            />
          </Stack>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <Stack display={"flex"} direction={"column"} gap={2}>
            <Typography
              align={matchesSM ? "center" : "left"}
              component={"p"}
              variant="h6"
            >
              Receive a link via email
            </Typography>

            <Typography
              align={matchesSM ? "center" : "left"}
              component={"p"}
              variant="body1"
            >
              Enter your email address and we will send you a link to complete
              this verification on a different device.
            </Typography>

            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                endAdornment: <EmailIcon />,
              }}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "#fff",
                },
              }}
            />

            <Typography
              align={matchesSM ? "center" : "left"}
              component={"p"}
              variant="body2"
            >
              If you do not see the email, then please check your spam folder.
            </Typography>
            <CvsButton
              title={isLoading ? <CircularProgress /> : "Send Link"}
              skin={skin}
              onClick={sendEmail}
            />
          </Stack>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Stack display={"flex"} direction={"column"} gap={2}>
            <Typography
              align={matchesSM ? "center" : "left"}
              component={"p"}
              variant="h6"
            >
              Copy link
            </Typography>

            <Typography
              align={matchesSM ? "center" : "left"}
              component={"p"}
              variant="body1"
            >
              Open this link to complete this verification on a different
              device.
            </Typography>

            <Button
              endIcon={
                <ContentCopy
                  sx={{
                    color: theme?.palette?.common?.black,
                  }}
                />
              }
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(
                  redirectUrl
                    ? window.location.origin + redirectUrl
                    : window.location.toString()
                );
                showToast("Link copied to clipboard", "success");
              }}
              style={{
                ...styles.continueButton,
                marginTop: 0,
                display: "flex",
              }}
              className={classes.continueButton}
            >
              <Typography
                component={"p"}
                fontSize={15}
                color={theme?.palette?.common?.black}
                fontWeight={700}
                textTransform="capitalize"
              >
                Copy link
              </Typography>
            </Button>
          </Stack>
        </TabPanel>
      </Grid>
    </Grid>
  );
}
