import * as React from "react";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import NoHat from "../../assets/no-hat.png";
import GoodLighting from "../../assets/good-lighting.png";
import NoGlasses from "../../assets/glass-selfie.png";
import NoMask from "../../assets/no-mask.png";
import { useInterval } from "../../utils/useInterval";
import { DlActionEnum } from "../DLScanning/DLFaceCompare";
import { UserContext } from "../../context/UserContext";
import { useMediaQuery, useTheme } from "@mui/material";
import DlFront from "../../assets/front-dl.png";
import DlBack from "../../assets/back-dl.png";

const steps = [
  {
    icon: NoHat,
    label: `Remove Hat`,
  },
  {
    label: "Ensure good lighting",
    icon: GoodLighting,
  },
  {
    label: "Remove glasses for selfie",
    icon: NoGlasses,
  },
  {
    label: "Take off facemask",
    icon: NoMask,
  },
];

const documentStep = [
  {
    icon: DlFront,
    label: `Please show the front of your Driver’s License`,
  },
];

const backDocumentStep = [
  {
    icon: DlBack,
    label: `Please show the back of your Driver’s License`,
  },
];

export default function InstructionsStepper({
  finishInstructionSlider,
  isDocumentScan,
}: {
  finishInstructionSlider: () => void;
  isDocumentScan: boolean;
}) {
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const context = React.useContext(UserContext);
  const { dlAction } = context;
  const interval = isDocumentScan ? 3500 : 2000;
  const [activeStep, setActiveStep] = React.useState(0);
  useInterval(() => {
    if (maxSteps === 1) {
      finishInstructionSlider();
    } else {
      setActiveStep(
        (prevActiveStep) => (prevActiveStep === 3 ? -1 : prevActiveStep) + 1
      );
    }
  }, interval);
  const maxSteps = isDocumentScan
    ? dlAction === DlActionEnum.backscan
      ? backDocumentStep?.length
      : documentStep?.length
    : steps.length;

  React.useEffect(() => {
    if (activeStep === maxSteps - 1 && maxSteps > 3) {
      finishInstructionSlider();
    }
  }, [activeStep]);

  const step = isDocumentScan
    ? dlAction === DlActionEnum.backscan
      ? backDocumentStep
      : documentStep
    : steps;

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      sx={{
        // height: 255,
        p: 2,
        maxWidth: 400,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {step[activeStep]?.icon && (
        <img
          style={{ width: "100px" }}
          src={step[activeStep]?.icon}
          alt={"active-step"}
        />
      )}
      <Typography
        variant="h4"
        color={"#262626"}
        ml={matchesSM ? 2 : 0}
        fontSize={matchesSM ? "24px" : "2.125rem"}
        textAlign={matchesSM ? "center" : "center"}
        width={matchesSM ? "100%" : 300}
        className="headingWrap"
      >
        {step[activeStep]?.label}
      </Typography>
      <MobileStepper
        variant="progress"
        sx={{ backgroundColor: "#ffe97d" }}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={null}
        backButton={null}
      />
    </Box>
  );
}
