import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress
} from "@mui/material";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";

import { styles as mainStyles } from "../../styles/styles";
import { stopCamera } from "../../utils";
import { createVerificationSession } from "../../services/api";
import Header from "../../components/Header";
import { headerVisible } from "../../theme";
import config from "../../config";
import ultraPassLogo from "../../assets/ultrapassLogo.png";
import { useStyles } from "../home/styles";
import SignInWithMessage from "../../components/SignInWithMessage";

interface AuthenticateProps {
  theme: string;
  skin: string;
}

const Authenticate = ({ theme, skin }: AuthenticateProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAuthenticate, setIsAuthenticate] = useState(false);
  const [loadingAuthenticate, setLoadingAuthenticate] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const user = JSON.parse(localStorage.getItem("user") ?? "{}");
  const themeName = skin || "primary";

  useEffect(() => {
    if (user?._id) {
      stopCamera();
      navigate("/");
    }
  }, [user]);

  const createVerification = async () => {
    setLoading(true);
    const payload = config.clientConfig;
    const result: any = await createVerificationSession(payload);
    if (result?.token) {
      stopCamera();
      navigate({
        pathname: "/register",
        search: createSearchParams({
          token: result?.token || "",
        }).toString(),
      });
    }
    setLoading(false);
  };

  const onAuthenticate = () => {
    setLoadingAuthenticate(true);
    setIsAuthenticate(true);
  };

  return (
    <>
      {headerVisible?.includes(skin) && <Header theme={themeName} />}
      <div className="homePageWrapper homePageWrapperMobile">
        {!isModalOpen && (
          <Container maxWidth="lg">
            <Box className={classes.mainWrap}>
              <Box className={classes.innerWrap}>
                <img src={ultraPassLogo} width={300} alt="" />
                <Typography
                  component="p"
                  color={`${theme}.listText`}
                  fontSize={30}
                  fontWeight={500}
                  className={classes.homeSubHeading}
                  mt={1}
                >
                  Private Verified Identity
                </Typography>
              </Box>
              <Box pt={5} className={classes.buttonsGrid}>
                <Grid
                  container
                  alignItems="center"
                  className={classes.buttonsWrap}
                >
                  <Button
                    sx={{ textTransform: "unset", opacity: loading ? 0.8 : 1 }}
                    variant="contained"
                    style={mainStyles.ageVerifiedButton}
                    onClick={() => onAuthenticate()}
                    disabled={loading}
                    className={classes.buttonsWrapButton}
                  >
                    {loadingAuthenticate ? (
                      <CircularProgress className={classes.homeLoader} />
                    ) : (
                      "Authenticate Now"
                    )}
                  </Button>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  className={classes.buttonsWrap}
                >
                  <Button
                    sx={{ textTransform: "unset", opacity: loading ? 0.8 : 1 }}
                    variant="contained"
                    style={mainStyles.ageVerifiedButton}
                    onClick={createVerification}
                    disabled={loading}
                    className={classes.buttonsWrapButton}
                  >
                    {loading ? (
                      <CircularProgress className={classes.homeLoader} />
                    ) : (
                      "Verify me"
                    )}
                  </Button>
                  {matchesSM ? null : (
                    <Typography
                      component="p"
                      style={mainStyles.ageLearnMoreButton}
                      sx={{
                        textAlign: "center",
                      }}
                      className={classes.buttonsWrapButton}
                    >
                      Let's make healthier happen together!
                    </Typography>
                  )}
                </Grid>
              </Box>
            </Box>
          </Container>
        )}
        {isAuthenticate && <SignInWithMessage skin={skin} onModalVisible={(e) => setIsModalOpen(e)} withBarcode={false} />}
      </div>
    </>
  );
};

export default Authenticate;
