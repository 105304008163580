import * as React from "react";
import "react-phone-number-input/style.css";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import OptionTab from "../../OptionsTab/optionTab";

export default function SwitchDeviceWithoutToken({redirectUrl}:any) {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = React.useState(0);
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <OptionTab
      value={value}
      handleChange={handleChange}
      matchesSM={matchesSM}
      phone={phone}
      setPhone={setPhone}
      email={email}
      setEmail={setEmail}
      stopApi={true}
      redirectUrl={redirectUrl}
    />
  );
}
