import { useState } from "react";
import Header from "../../components/Header";
import { headerVisible } from "../../theme";
import HomeComponent from "../../components/HomeComponent";
import SignInWithMessage from "../../components/SignInWithMessage";
import bannerBottom from "../../assets/bannerBottom.png";
import { closeCamera } from "@privateid/cryptonets-web-sdk-alpha";
import { ELEMENT_ID } from "../../constants";
import { useNavigate } from "react-router";
import { Container, useMediaQuery, useTheme } from "@mui/material";
interface SignInProps {
  theme: string;
  skin: string;
  withBarcode: boolean;
}

const Signin = ({ skin, withBarcode }: SignInProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const themeName = skin || "primary";
  const navigate = useNavigate();
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const onCloseCamera = async () => {
    await closeCamera(ELEMENT_ID);
    setTimeout(() => {
      navigate("/");
    }, 500);
  };
  return (
    <>
      {headerVisible?.includes(skin) && (
        <Header theme={themeName} onCloseCamera={onCloseCamera} />
      )}
      <Container maxWidth="lg" disableGutters={matchesSM}>
        <div className={`homePageWrapper homeComponent homeComponentWrap ${isModalOpen && 'inLandscape'}`} style={{flexDirection: "column"}}>
          {!isModalOpen && <HomeComponent theme={themeName} />}
          <SignInWithMessage
            skin={skin}
            onModalVisible={(e) => setIsModalOpen(e)}
            withBarcode={withBarcode}
          />
        </div>
      </Container>
      <Container maxWidth="lg">
        <img src={bannerBottom} alt="BannerBottom" className="bannerBottom" />
      </Container>
    </>
  );
};

export default Signin;
