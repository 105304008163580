import {
  Grid,
  Typography,
} from "@mui/material";
import { styles, useStyles } from "../../../styles/styles";
import IDLaptopImage from "../../../assets/Hand-Scan-Back-DL.png";
import STEPS from "../../../pages/login_with_barcode/steps";
import CvsButton from "../../Button";

interface PreDocumentScanProps {
  skin: string;
  setStep: (e: string) => void;
  config?: {};
}

const PreDocumentScan = (props: PreDocumentScanProps) => {
  const { skin, setStep } = props;
  const classes = useStyles();
  return (
    <>
      <Grid style={styles.cardGrid} className={classes.cardGridMobile}>
        <Typography
          component="p"
          textAlign={"center"}
          fontSize={16}
          fontWeight={700}
          mt={2}
          className={classes.cardInnerHeading}
          letterSpacing={1}
        >
          Get ready to scan the back of your
          <br /> US Driver's License or ID Card
        </Typography>
        <img src={IDLaptopImage} alt="" className={classes.dlImage} />
      </Grid>
      <CvsButton
        title="Continue"
        onClick={() => {
          setStep(STEPS.BACK_DOCUMENT_SCAN);
        }}
        skin={skin}
      />
    </>
  );
};

export default PreDocumentScan;
